export default {
  analysisData(pos: number, responseText: string, callback: (...arg: any) => void) {
    const str = responseText.trim()
    // 把全部字符串的每一项数据解析成数组
    const arr = str.match(/data: (.*)/g) ?? []
    // i = pos 是因为跳过前面的数据
    // pos是做标记，因为它返回的数据是带着上一轮的数据的，所以得记录一下
    for (let i = pos; i < arr.length; i++) {
      let n = arr[i]
      // 解析data:
      n = n.substring(6)

      // 开始流工作

      // 判断是否结束
      if (n === '[DONE]') {
        console.log('结束流')
        callback(n)
        return
      }
      try {
        const msgItem = JSON.parse(n)
        callback(msgItem)
      } catch (error) {
        console.log('解析出错: ', error)
      }
    }
  },
  analysisData2(responseText: string, callback: (...arg: any) => void) {
    const str = responseText.trim()
    const arr = str.match(/data: (.*)/g) ?? []

    for (let i = 0; i < arr.length; i++) {
      let n = arr[i]
      // 解析data:
      n = n.substring(6)

      // 开始流工作

      // 判断是否结束
      if (n === '[DONE]') {
        console.log('结束流')
        callback(n)
        return
      }
      try {
        const msgItem = JSON.parse(n)
        callback(msgItem)
      } catch (error) {
        console.log('解析出错: ', error)
      }
    }
  },
}
