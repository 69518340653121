import React, { memo, useEffect, useRef, useState } from 'react'
import CSS from './index.module.scss'
import classNames from 'classnames'

const Tabs = memo((props: any = {}) => {
  const { list = [], activatedItemClass = 'activated_item', onChange } = props
  const [tabIndex, setTabIndex] = useState(0)
  const [linkX, setLinkX] = useState(0)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const linkRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const conChild = contentRef.current?.children
    if (conChild) {
      const currentItem = Array.from(conChild)[tabIndex] as HTMLDivElement
      const linkEl = linkRef.current as HTMLDivElement
      setLinkX(currentItem.offsetLeft + currentItem.offsetWidth * 0.5 - linkEl.offsetWidth * 0.5)
    }
  }, [tabIndex])

  const changeItem = (item: any, index: number) => {
    setTabIndex(index)
    onChange?.(item, index)
  }

  return (
    <div className={CSS.tabs_wrapper}>
      <div className={CSS.content} ref={contentRef}>
        {list.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className={classNames([CSS.item, index === tabIndex && activatedItemClass])}
              onClick={() => changeItem(item, index)}
            >
              {item.text}
            </div>
          )
        })}
      </div>
      <div className={CSS.link} ref={linkRef} style={{ transform: `translateX(${linkX}px)` }}></div>
    </div>
  )
})

export default Tabs
