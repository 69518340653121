import React, { memo, useState, forwardRef, useImperativeHandle } from 'react'
// import Tabs from '../Tbas/Tabs'
import Tabs from '@/components/Tabs'
import CodeLogin from '../CodeLogin'
import PasswordLogin from '../PasswordLogin'
import antdUI from '@/antd-ui'
import { verifyMobile } from '@/utils'

const tabList = [
  {
    text: '验证码登录',
  },
  {
    text: '密码登录',
  },
]

const Login = forwardRef((props: any = {}, ref) => {
  const [tabIndex, setTabIndex] = useState(0)

  const [loginFormData, setLoginFormData] = useState({
    mobile: '',
    captcha: '',
    password: '',
  })

  const onChange = (value: string, type: string) => {
    setLoginFormData({
      ...loginFormData,
      [type]: value,
    })
  }

  const verifyState = () => {
    if (!verifyMobile(loginFormData.mobile)) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请输入正确的手机号',
      })
      return
    }
    if (tabIndex === 0) {
      if (!loginFormData.captcha.trim()) {
        antdUI.messageApi.open({
          type: 'warning',
          content: '请输入验证码',
        })
        return
      }
    }
    if (tabIndex === 1) {
      if (loginFormData.password.length < 6 || loginFormData.password.length > 18) {
        antdUI.messageApi.open({
          type: 'warning',
          content: '请输入6-18位密码',
        })
        return
      }
    }
    return true
  }

  const getTabIndex = () => {
    return tabIndex
  }

  useImperativeHandle(ref, () => ({
    verifyState,
    loginFormData,
    getTabIndex,
  }))

  return (
    <div>
      <div className="flex justify-center">
        <Tabs list={tabList} onChange={(_: any, i: number) => setTabIndex(i)} />
      </div>
      <div style={{ display: tabIndex === 0 ? 'block' : 'none' }}>
        <CodeLogin loginFormData={loginFormData} onChange={onChange} />
      </div>
      <div style={{ display: tabIndex === 1 ? 'block' : 'none' }}>
        <PasswordLogin
          loginFormData={loginFormData}
          onChange={onChange}
          onSetType={props.onSetType}
        />
      </div>
    </div>
  )
})

export default memo(Login)
