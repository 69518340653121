import React, { memo } from 'react'

const HistoryIcon = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.99998 1.33333C11.682 1.33333 14.6666 4.318 14.6666 8C14.6666 11.682 11.682 14.6667 7.99998 14.6667C4.31798 14.6667 1.33331 11.682 1.33331 8H2.66665C2.66679 9.27698 3.12511 10.5115 3.9583 11.4792C4.79149 12.4469 5.94424 13.0836 7.20703 13.2734C8.46982 13.4633 9.75881 13.1938 10.8397 12.5138C11.9206 11.8339 12.7217 10.7887 13.0973 9.56824C13.473 8.34776 13.3982 7.03302 12.8867 5.86297C12.3752 4.69292 11.4608 3.74525 10.3098 3.19219C9.15881 2.63914 7.84758 2.51743 6.61444 2.84917C5.38131 3.18091 4.30813 3.9441 3.58998 5H5.33331V6.33333H1.33331V2.33333H2.66665V4C3.28709 3.17145 4.0922 2.499 5.01803 2.03609C5.94385 1.57318 6.96488 1.33255 7.99998 1.33333ZM8.66665 4.66666V7.72333L10.8286 9.88533L9.88531 10.8287L7.33331 8.27533V4.66666H8.66665Z"
        fill="#2D374C"
      />
    </svg>
  )
})

export default HistoryIcon
