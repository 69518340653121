import React, { memo, useEffect, useState } from 'react'
import { Modal } from 'antd'
import CSS from './index.module.scss'
import { getUserAgree, getPrivacyPolicy } from '@/api/user'
import classNames from 'classnames'

const Agreement = memo((props: any = {}) => {
  const { open = false, title = '', onCancel } = props
  const [contentInfo, setContentInfo] = useState({
    userAgree: '',
    privacyPolicy: '',
  })
  useEffect(() => {
    getUserAgree().then((res: any) => {
      console.log('res: ', res)
      setContentInfo(state => ({ ...state, userAgree: res.data?.content }))
    })
    getPrivacyPolicy().then((res: any) => {
      console.log('res: ', res)
      setContentInfo(state => ({ ...state, privacyPolicy: res.data?.content }))
    })
  }, [])
  return (
    <Modal
      title={<div className={CSS.title}>{title}</div>}
      className={CSS.agreement_modal}
      // width={'fit-content'}
      width={800}
      maskClosable={true}
      open={open}
      footer={null}
      zIndex={1001}
      onCancel={onCancel}
    >
      <div
        className={classNames([CSS.content, 'scrollbar_style'])}
        dangerouslySetInnerHTML={{
          __html: title === '用户协议' ? contentInfo.userAgree : contentInfo.privacyPolicy,
        }}
      ></div>
    </Modal>
  )
})

export default Agreement
