import React, { memo } from 'react'
import CSS from './index.module.scss'
import Login from '@/components/Login'
import useIndexStore from '@/store'
import { Button, Modal } from 'antd'
import androidCodeImg from '@/assets/images/rq_code/android_code.png'
import { copyTool } from '@/utils/tool'
import { ANDROID_APK_URL } from '@/constant/apk'
import Copy1 from '@/assets/svg/Copy1'
import useUserStore from '@/store/user'
import History from '@/components/History'

const GlobalPopUp = memo(() => {
  const loginSwitch = useIndexStore(state => state.loginSwitch)

  const { isShowPayGuidance, changeIsShowPayGuidance, guidanceTitle } = useUserStore(state => ({
    isShowPayGuidance: state.isShowPayGuidance,
    changeIsShowPayGuidance: state.changeIsShowPayGuidance,
    guidanceTitle: state.guidanceTitle,
  }))

  return (
    <>
      {/* 登录弹框 */}
      <Login />
      {/* 会员购买弹框 */}
      <Modal
        open={isShowPayGuidance}
        footer={null}
        onOk={() => changeIsShowPayGuidance(false)}
        onCancel={() => changeIsShowPayGuidance(false)}
      >
        <div className={CSS.pay_modal_box}>
          <div className={CSS.pay_modal_guidance}>{guidanceTitle}</div>
          <div className={CSS.pay_modal_rq_box}>
            <img src={androidCodeImg} alt="img" />
            <div>扫一扫上方二维码下载APP</div>
          </div>
          <div className={CSS.pay_modal_downlaod_box}>
            <span>{ANDROID_APK_URL}</span>
            <div className={CSS.copy_btn_box}>
              <Button type="primary" icon={<Copy1 />} onClick={() => copyTool(ANDROID_APK_URL)}>
                复制下载链接
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <History />
    </>
  )
})

export default GlobalPopUp
