import antdUI from '@/antd-ui'

export const notificationTool = (text: any, type = 'success') => {
  ;(antdUI.notificationApi as any)[type]({
    message: text,
  })
}

export const copyTool = (text: any) => {
  /* 复制内容到文本域 */
  window.navigator.clipboard.writeText(text)
  notificationTool('复制成功')
}
