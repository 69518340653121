import React, { memo } from 'react'

const TimeIonc1 = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M10.25 18.3334C14.8525 18.3334 18.5834 14.6026 18.5834 10.0001C18.5834 5.39758 14.8525 1.66675 10.25 1.66675C5.64752 1.66675 1.91669 5.39758 1.91669 10.0001C1.91669 14.6026 5.64752 18.3334 10.25 18.3334Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M10.2534 5V10.0042L13.7863 13.5375"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})

export default TimeIonc1
