import { bjRequest } from '..'

/**
 * 获取助理大厅tabs
 */
export function getSceneHallTabs() {
  return bjRequest.get({
    url: '/api/Scene/hall',
  })
}

/**
 * 获取助理大厅的列表
 * @param unique_id tabs id
 */
export function getSceneExampleList(unique_id: any) {
  return bjRequest.get({
    url: `/api/Scene/example?unique_id=${unique_id}`,
  })
}
