import React, { memo } from 'react'

const close = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M4.26665 13.1667L3.33331 12.2333L7.06665 8.5L3.33331 4.76667L4.26665 3.83334L7.99998 7.56667L11.7333 3.83334L12.6666 4.76667L8.93331 8.5L12.6666 12.2333L11.7333 13.1667L7.99998 9.43334L4.26665 13.1667Z"
        fill="#4A5D82"
      />
    </svg>
  )
})

export default close
