import React, { FC, Fragment, memo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RightOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import CSS from './index.module.scss'
import useIndexStore from '@/store'
import DefaultAvatar from '@/assets/svg/DefaultAvatar'
import { Avatar, Modal, Popover } from 'antd'
import useUserStore from '@/store/user'
import HistoryIcon from '@/assets/svg/HistoryIcon'
import DistributionIcon from '@/assets/svg/DistributionIcon'
import OutLoginIcon from '@/assets/svg/OutLoginIcon'
import ArrowsLeft1 from '@/assets/svg/ArrowsLeft1'
import androidCodeImg from '@/assets/images/rq_code/android_code.png'
import noVipImg from '@/assets/images/vip/no_vip.png'
import vipImg from '@/assets/images/vip/vip.png'

import cache from '@/utils/cache'
import { GUIDANCE_DISTRIBUTION_TITLE, GUIDANCE_PAY_TITLE } from '@/constant'
import Phone1 from '@/assets/svg/Phone1'

interface IProps {
  center: JSX.Element
  left?: JSX.Element
  right?: JSX.Element
  isBack?: boolean
}

const showConfirm = () => {
  Modal.confirm({
    title: '温馨提示',
    icon: <ExclamationCircleOutlined />,
    content: '是否退出登录？',
    onOk() {
      console.log('OK')
      cache.clearCache()
      location.reload()
    },
    onCancel() {
      console.log('Cancel')
    },
  })
}

const Header: FC<IProps> = memo((props: IProps) => {
  const navigate = useNavigate()
  const token = useIndexStore(state => state.token)
  const userInfo: any = useUserStore(state => state.userInfo)
  const changeLoginSwitch = useIndexStore(state => state.changeLoginSwitch)
  const changeIsShowPayGuidance = useUserStore(state => state.changeIsShowPayGuidance)
  const changeIsShowHistory = useUserStore(state => state.changeIsShowHistory)
  const [popoverOpen, setPopoverOpen] = useState(false)

  // console.log('token: ', token)
  const loginHandle = () => {
    changeLoginSwitch(true)
  }

  const backClick = () => {
    navigate(-1)
  }

  const outLoginHandle = () => {
    setPopoverOpen(false)
    showConfirm()
  }

  const memberItemHandle = () => {
    setPopoverOpen(false)
    changeIsShowPayGuidance(true, GUIDANCE_PAY_TITLE)
  }

  const distributionHandle = () => {
    setPopoverOpen(false)
    changeIsShowPayGuidance(true, GUIDANCE_DISTRIBUTION_TITLE)
  }

  const historyItemHandle = () => {
    setPopoverOpen(false)
    changeIsShowHistory(true)
  }

  const popoverElement = (
    <div className={CSS.popover}>
      {/* item title */}
      <div className={classNames([CSS.popover_item, 'filex_space_between'])}>
        <div className={classNames([CSS.item_left, 'filex_center'])}>
          <div>
            <Avatar
              size={32}
              src={userInfo?.avatar}
              icon={<DefaultAvatar color="#0A78F9" />}
              style={{ background: 'transparent' }}
            />
          </div>
          <div className={classNames(['ml-3', CSS.username])}>{userInfo?.username}</div>
        </div>
        {/* <div className={classNames([CSS.item_right])}>
          <span className={CSS.sign_text}>签到</span>
        </div> */}
      </div>
      {/* item */}
      <div className={classNames([CSS.popover_item, 'filex_space_between'])}>
        <div className={classNames([CSS.item_left])}>
          <img
            className={CSS.vip_img}
            src={(userInfo?.exceedtime ?? 0) > 0 ? vipImg : noVipImg}
            alt="img"
          />
          <span className="ml10">
            {(userInfo?.exceedtime ?? 0) > 0 ? `${userInfo.exceedtime}天` : '免费会员'}
          </span>
        </div>
        <div className={classNames([CSS.item_right])} onClick={memberItemHandle}>
          <span>{(userInfo?.exceedtime ?? 0) > 0 ? '续费' : '开通会员'}</span>
        </div>
      </div>
      {/* item */}
      <div className={classNames([CSS.popover_item, 'filex_space_between'])}>
        <div className={classNames([CSS.item_left])}>
          {userInfo?.number === -1 ? (
            <Fragment>
              <span>活动期间不限次数</span>
            </Fragment>
          ) : (
            <Fragment>
              <span>剩余次数：</span>
              <span>{userInfo?.number ?? 0}次</span>
            </Fragment>
          )}
        </div>
        <div className={classNames([CSS.item_right])} onClick={memberItemHandle}>
          <span>购买次数</span>
        </div>
      </div>
      {/* item 历史记录 */}
      <div
        className={classNames([CSS.popover_item, 'filex_space_between', 'cursor_pointer'])}
        onClick={historyItemHandle}
      >
        <div className={classNames([CSS.item_left, 'filex_align_center', 'cursor_pointer'])}>
          <HistoryIcon />
          <span className="ml10">历史记录</span>
        </div>
        <div className={classNames([CSS.item_right])}>
          <RightOutlined style={{ color: '#D4D4D4' }} />
        </div>
      </div>
      {/* item 分销中心 */}
      <div
        className={classNames([CSS.popover_item, 'filex_space_between', 'cursor_pointer'])}
        onClick={distributionHandle}
      >
        <div className={classNames([CSS.item_left, 'filex_align_center'])}>
          <DistributionIcon />
          <span className="ml10">分销中心</span>
        </div>
        <div className={classNames([CSS.item_right])}>
          <RightOutlined style={{ color: '#D4D4D4' }} />
        </div>
      </div>
      {/* item 退出登录 */}
      <div
        className={classNames([CSS.popover_item, 'filex_space_between', 'cursor_pointer'])}
        onClick={outLoginHandle}
      >
        <div className={classNames([CSS.item_left, 'filex_align_center'])}>
          <OutLoginIcon />
          <span className="ml10 out_login_text">退出登录</span>
        </div>
        <div className={classNames([CSS.item_right])}>
          <RightOutlined style={{ color: '#D4D4D4' }} />
        </div>
      </div>
    </div>
  )
  return (
    <header className={CSS.wrapper}>
      <div className={CSS.left}>
        {props.isBack ? (
          <div className={classNames([CSS.back_box, 'filex_align_center'])} onClick={backClick}>
            <ArrowsLeft1 />
            <span className="ml10">返回</span>
          </div>
        ) : (
          props.left
        )}
      </div>
      <div className={classNames([CSS.center, CSS.filex_align_center])}>{props.center}</div>
      <div className={classNames([CSS.right, CSS.filex_align_center])}>
        <Popover
          overlayClassName={CSS.rq_popover}
          placement="bottom"
          content={
            <div className={classNames([CSS['rq_code']])}>
              <div className={CSS['rq_img']}>
                <img src={androidCodeImg} alt="img" />
              </div>
              <div className={CSS['rq_text']}>扫码下载安装包</div>
            </div>
          }
          trigger="hover"
        >
          <div className={CSS.download_box}>
            <Phone1 />
            <span>安卓端</span>
          </div>
        </Popover>
        {props.right ? (
          props.right
        ) : (
          <div className={CSS.avatar_box}>
            {token && userInfo ? (
              <div
                className={CSS.user_avatar_box}
                onMouseEnter={() => setPopoverOpen(true)}
                onMouseLeave={() => setPopoverOpen(false)}
              >
                {/* {userInfo ? <img src={userInfo.avatar} alt="avatar" /> : <DefaultAvatar />} */}

                {/* open={popoverOpen} */}
                <Popover
                  open={popoverOpen}
                  content={popoverElement}
                  placement="bottomLeft"
                  overlayClassName={CSS.user_info_popover}
                  trigger="click"
                >
                  <Avatar
                    size={34}
                    src={userInfo?.avatar}
                    icon={<DefaultAvatar color="#0A78F9" />}
                    style={{ background: 'transparent' }}
                  />
                </Popover>
              </div>
            ) : (
              <div className={CSS.no_login_box}>
                <div className={CSS.no_login} onClick={loginHandle}>
                  <div className={CSS.no_login_left}>
                    <DefaultAvatar />
                  </div>
                  <div className={CSS.no_login_text}>登录</div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  )
})

export default Header
