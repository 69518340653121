import React, { memo, useEffect, useRef } from 'react'
import classNames from 'classnames'
import CSS from './index.module.scss'
import MsgIcon1 from '@/assets/svg/MsgIcon1'
import TimeIcon2 from '@/assets/svg/TimeIcon2'
import Reload2 from '@/assets/svg/Reload2'
import MicrophoneIcon1 from '@/assets/svg/MicrophoneIcon1'
import { Button, Input, Tooltip, message, notification } from 'antd'
import useUserStore from '@/store/user'
import useChatToolStore from '@/store/chatTool'
import useIndexStore from '@/store'
import { fetchPostStream2, getSessionId } from '@/api/chat'

import analysisStreamApi from '@/utils/analysisStream'

import { smoothScrollTo } from '@/utils'

import { createChatItem } from '@/utils/chat'
import { useLocation, useNavigate } from 'react-router-dom'
import MemberInfo from '@/components/MemberInfo'
import { useRequest } from '@/hooks/useRequest'
import useStreamTypewriting from '@/hooks/useStreamTypewriting'

const notificationSuccess = (text: any) => {
  notification['success']({
    message: text,
  })
}
const notificationError = (text: any) => {
  notification['error']({
    message: text,
  })
}
interface IProps {
  listContainerRef: any
}
// 缓存dom
const cacheDom: any = {}

const InputContainer = memo((props: IProps) => {
  const { listContainerRef } = props
  cacheDom.listContainerRef = listContainerRef
  const location = useLocation()
  const navigate = useNavigate()
  const changeLoginSwitch = useIndexStore(state => state.changeLoginSwitch)
  const token = useIndexStore(state => state.token)
  const userInfo: any = useUserStore(state => state.userInfo)
  const fetchUserInfo = useUserStore(state => state.fetchUserInfo)
  const {
    isInput,
    context,
    retrySessionChatItem,
    sessionList,
    sessionDatas,
    fetchController,
    changeContext,
    changeUserInput,
    changeIsInput,
    changeListPush,
    changeCurrentChatItemMsg,
    clearContext,
    changeRetrySessionChatItem,
    initContext,
    changeChatItemDone,
    changeFetchController,
  } = useChatToolStore()
  let userInput = useChatToolStore(state => state.userInput)
  const getSessionIdReq = useRequest(getSessionId)
  const streamTypewritingTool = useStreamTypewriting()
  const sessionId = useRef(null)

  const changeIsScroll = (flag: boolean) => {
    if (cacheDom.listContainerRef) {
      cacheDom.listContainerRef._isScroll = flag
    }
  }

  const getSessionIdHandle = async (scene_unique_id: number) => {
    if (!token) return
    const [err, res] = await getSessionIdReq.run({ scene_unique_id })
    if (err) return
    sessionId.current = res?.data?.session_id
    return sessionId.current
  }

  const assistantItem = location.state?.assistantItem
  // console.log('assistantItem: ', assistantItem)
  useEffect(() => {
    // console.log('sessionDatas1: ', sessionDatas)
    if (sessionList.length === 0) {
      navigate('/chat')
    }

    if (assistantItem) {
      ;(async () => {
        // 先获取会话id再操作
        // const [err, res] = await getSessionIdReq.run({ scene_unique_id: assistantItem.unique_id })
        // if (err) return
        // sessionId.current = res?.data?.session_id
        await getSessionIdHandle(assistantItem.unique_id)
        // 如果有缓存就拿缓存的
        const currentSessionDataList = (sessionDatas as any)[assistantItem.unique_id]
        // console.log('currentSessionDataList: ', currentSessionDataList)
        if (currentSessionDataList?.length) {
          // changeList(currentSessionDataList)
          // 初始化上下文
          initContext(assistantItem?.unique_id)
          // 滚动到底部
          setTimeout(changeIsScroll, 1, true)
          setTimeout(scrollToBottom, 1)
          return
        }
        // 如果没有缓存 list也没有值的话那就push第一条介绍
        if (currentSessionDataList === void 0 || currentSessionDataList.length === 0) {
          const oneChatItem = createChatItem({
            isTool: false,
            created_at: '',
            assistant: assistantItem.intro,
            unique_id: assistantItem?.unique_id,
          })
          changeListPush(oneChatItem)
          setTimeout(() => {
            console.log('发起请求')
            changeUserInput(assistantItem.text)
            userInput = assistantItem.text
            submitPostStream()
          }, 100)
        }
      })()
    } else {
      navigate('/chat')
    }
  }, [assistantItem])

  useEffect(() => {
    return () => {
      // console.log('----------------------------------------')
      // fetchController?.abort?.()
      // changeList([])
      // const isInput = useChatStore.getState().isInput
      // console.log('isInput: ', isInput)
      // if (isInput) {
      //   changeSessionDatas(assistantItem.unique_id)
      //   changeChatItemDone(newChatItem)
      // }
      // 初始化上下文
      initContext(assistantItem?.unique_id, [])
    }
  }, [])

  // 监听重试
  useEffect(() => {
    if (retrySessionChatItem) {
      changeUserInput((retrySessionChatItem as any).chat.user)
      userInput = (retrySessionChatItem as any).chat.user
      submitPostStream()
      changeRetrySessionChatItem(null)
    }
  }, [retrySessionChatItem])

  const scrollToBottom = () => {
    // 是否滚动
    if (cacheDom.listContainerRef?._isScroll) {
      smoothScrollTo(cacheDom.listContainerRef, cacheDom.listContainerRef.scrollHeight + 9999)
    }
  }

  const submitPostStream = async (chatItemConfig: any = {}) => {
    if (!token) {
      message.warning('请先登录~')
      changeLoginSwitch(true)
      return
    }

    if (userInput.trim() === '') {
      notificationError('请输入你的问题再提交~')
      return
    }

    if (!sessionId.current) {
      await getSessionIdHandle(assistantItem?.unique_id)
    }

    const newChatItem = createChatItem({
      user: userInput,
      assistant: '',
      unique_id: assistantItem?.unique_id,
      ...chatItemConfig,
    })

    changeListPush(newChatItem)
    changeIsInput(true)
    changeUserInput('')

    if (cacheDom.listContainerRef) {
      smoothScrollTo(cacheDom.listContainerRef, cacheDom.listContainerRef.scrollHeight + 9999)
      cacheDom.listContainerRef._isScroll = true
      cacheDom.listContainerRef._prevScrollTop = 0
    }

    // 开始打字
    streamTypewritingTool.typewriting()

    // 监听打字
    streamTypewritingTool.config.streamCallback = (text: string) => {
      changeCurrentChatItemMsg(assistantItem?.unique_id, text)
      // 页面滚动
      scrollToBottom()
    }
    // 监听结束
    streamTypewritingTool.config.endCallback = () => {
      // 打完字解放禁止操作状态
      changeIsInput(false)
    }

    try {
      // console.log('context: ', context)
      const fetchController = new AbortController()
      changeFetchController(fetchController)
      const resp = await fetchPostStream2({
        signal: fetchController.signal,
        data: {
          context,
          question: userInput,
          session_id: sessionId.current,
        },
        onDownloadProgress: (e: any) => {
          analysisStreamApi.analysisData2(e, (data: any) => {
            // console.log('data: ', data)
            // 判断是否结束
            if (data === '[DONE]') {
              changeChatItemDone(newChatItem)
              changeContext(newChatItem)
              return
            }

            const msg = data?.choices[0]?.delta?.content
            if (msg !== void 0) {
              // changeCurrentChatItemMsg(assistantItem?.unique_id, msg)
              // 滚动
              // scrollToBottom()
              streamTypewritingTool.config.tempChatAssistant += msg
            }
          })
        },
      })
      console.log('resp: ', resp)
      if (resp.status === 500) {
        changeCurrentChatItemMsg(assistantItem?.unique_id, '请先登录再操作哦')
        changeLoginSwitch(true)
      }
    } catch (error: any) {
      console.log('error: ', error)
      if (error.name === 'AbortError') {
        return
      }
      changeChatItemDone(newChatItem)
      const currentItemAssistant = (useChatToolStore.getState().sessionDatas as any)[
        assistantItem?.unique_id
      ]?.at(-1)?.chat?.assistant

      if (!currentItemAssistant) {
        changeCurrentChatItemMsg(
          assistantItem?.unique_id,
          '很抱歉，网络连接超时，请检查您的网络设置后再试。'
        )
      }
    } finally {
      fetchUserInfo({}, false)
    }

    // 结束打字
    streamTypewritingTool.endTypewriting()
  }

  const inputKeyDownHandle = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      submitPostStream()
    }
    return true
  }

  // 是否禁用重置按钮
  const isDisabledResetSession = isInput || context.length === 1
  const resetSessionHandle = () => {
    if (isDisabledResetSession) {
      return
    }
    clearContext()
    changeListPush({
      isResetSession: true,
      id: Date.now(),
      unique_id: assistantItem?.unique_id,
    })
    scrollToBottom()
  }

  return (
    <div className={classNames([CSS.input_box])}>
      <div className={CSS.input_content}>
        <div className={CSS.input_box_top}>
          <div className={CSS.input_top_left}></div>
          <div className={CSS.input_top_right}>
            <MemberInfo />
          </div>
        </div>
        <div className={CSS.input_box_bottom}>
          <Tooltip placement="top" title={<span>重置对话</span>}>
            <div
              className={classNames([
                CSS.input_box_realod,
                'filex_center',
                isDisabledResetSession ? 'cursor_not_allowed' : 'cursor_pointer',
              ])}
              onClick={resetSessionHandle}
            >
              <Reload2 />
            </div>
          </Tooltip>
          <div className={CSS.input_box_right}>
            {/* <textarea
              className="scrollbar_style2"
              placeholder={
                isInput ? '请耐心等待AI把话说完...' : '输入你想问的问题，Enter发送，Shift+Enter换行'
              }
              value={userInput}
              onChange={e => changeUserInput(e.target.value)}
              onKeyDown={inputKeyDownHandle}
            ></textarea> */}
            <Input.TextArea
              className="scrollbar_style"
              placeholder={
                isInput ? '请耐心等待AI把话说完...' : '输入你想问的问题，Enter发送，Shift+Enter换行'
              }
              autoSize={{ minRows: 1, maxRows: 3 }}
              value={userInput}
              onChange={e => changeUserInput(e.target.value)}
              onKeyDown={inputKeyDownHandle}
            />
            <div className={classNames([CSS.input_box_right_btn_box, 'filex_align_center'])}>
              {/* <div className="filex_align_center">
                <MicrophoneIcon1 />
              </div> */}
              <div className="filex_align_center">
                <Button
                  type="primary"
                  onClick={submitPostStream}
                  disabled={isInput || !userInput.trim()}
                >
                  发送
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default InputContainer
