import React, { memo } from 'react'

const OutLoginIcon = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M10 8.75V7.25H4.21429V5L1 8L4.21429 11V8.75H10Z" fill="#E74E4E" />
      <path
        d="M12.6154 2H6.38462C5.621 2 5 2.598 5 3.33333V6H6.38462V3.33333H12.6154V12.6667H6.38462V10H5V12.6667C5 13.402 5.621 14 6.38462 14H12.6154C13.379 14 14 13.402 14 12.6667V3.33333C14 2.598 13.379 2 12.6154 2Z"
        fill="#E74E4E"
      />
    </svg>
  )
})

export default OutLoginIcon
