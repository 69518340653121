import { bjRequest } from '../'

/**
 * 手机登录接口
 */
export function mobileLogin(data = {}) {
  return bjRequest.post({
    url: '/api/user/mobilelogin',
    data,
  })
}

/**
 * (新版)密码登录
 */
export function login(data = {}) {
  return bjRequest.post({
    url: '/api/user/login',
    data,
  })
}

/**
 * (新版)用户注册
 */
export function register(data = {}) {
  return bjRequest.post({
    url: '/api/user/register',
    data,
  })
}

/**
 * 获取手机验证码
 */
export function getMobileCaptcha(data = {}) {
  return bjRequest.post({
    url: '/api/Sms/send',
    data,
  })
}

/**
 * 校验token
 */
export function verifyToken() {
  return bjRequest.get({
    url: '/api/user/checkToken',
  })
}
