import React, { memo } from 'react'

const TimeIcon2 = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_31_164)">
        <mask id="mask0_31_164" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
          <path
            d="M8.00004 14.6666C11.682 14.6666 14.6667 11.682 14.6667 7.99998C14.6667 4.31798 11.682 1.33331 8.00004 1.33331C4.31804 1.33331 1.33337 4.31798 1.33337 7.99998C1.33337 11.682 4.31804 14.6666 8.00004 14.6666Z"
            fill="white"
            stroke="white"
            strokeWidth="1.33333"
            strokeLinejoin="round"
          />
          <path
            d="M8.00269 4V8.00333L10.829 10.83"
            stroke="black"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </mask>
        <g mask="url(#mask0_31_164)">
          <path d="M0 0H16V16H0V0Z" fill="#4A5D82" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_31_164">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
})

export default TimeIcon2
