import React, { memo, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import CSS from './index.module.scss'
import Reload2 from '@/assets/svg/Reload2'
import { Button, Input, Tooltip } from 'antd'
import useUserStore from '@/store/user'
import useChatStore from '@/store/chat'
import useIndexStore from '@/store'
import { fetchPostStream2, getSessionId } from '@/api/chat'

import analysisStreamApi from '@/utils/analysisStream'

import { smoothScrollTo } from '@/utils'

import { createChatItem } from '@/utils/chat'
import MemberInfo from '@/components/MemberInfo'
import Aeroplane from '@/assets/svg/Aeroplane'
import antdUI from '@/antd-ui'
import { useRequest } from '@/hooks/useRequest'
import useStreamTypewriting from '@/hooks/useStreamTypewriting'

interface IProps {
  listContainerRef: any
}
// 缓存dom
const cacheDom: any = {}

const InputContainer = memo((props: IProps) => {
  const { listContainerRef } = props
  cacheDom.listContainerRef = listContainerRef
  const changeLoginSwitch = useIndexStore(state => state.changeLoginSwitch)
  const token = useIndexStore(state => state.token)
  const userInfo: any = useUserStore(state => state.userInfo)
  const fetchUserInfo = useUserStore(state => state.fetchUserInfo)
  const getSessionIdReq = useRequest(getSessionId)
  const streamTypewritingTool = useStreamTypewriting()
  const {
    isInput,
    context,
    retrySessionChatItem,
    itemInfo,
    changeContext,
    changeUserInput,
    changeIsInput,
    changeListPush,
    changeCurrentChatItem,
    clearContext,
    changeRetrySessionChatItem,
    changeChatItemDone,
  } = useChatStore()
  let userInput = useChatStore(state => state.userInput)
  const sessionId = useRef(null)

  // 监听重试
  useEffect(() => {
    if (retrySessionChatItem) {
      changeUserInput((retrySessionChatItem as any).chat.user)
      userInput = (retrySessionChatItem as any).chat.user
      submitPostStream()
      changeRetrySessionChatItem(null)
    }
  }, [retrySessionChatItem])

  const getSessionIdHandle = async () => {
    if (!token) return
    const [err, res] = await getSessionIdReq.run({ scene_unique_id: 0 })
    if (err) return
    sessionId.current = res?.data?.session_id
    return sessionId.current
  }

  useEffect(() => {
    getSessionIdHandle()
  }, [])

  const scrollToBottom = () => {
    // 是否滚动
    if (cacheDom.listContainerRef?._isScroll) {
      smoothScrollTo(cacheDom.listContainerRef, cacheDom.listContainerRef.scrollHeight + 9999)
    }
  }

  const submitPostStream = async () => {
    if (!token) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请先登录~',
      })
      changeLoginSwitch(true)
      return
    }

    if (userInput.trim() === '') {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请输入你的问题再提交~',
      })
      return
    }

    if (!sessionId.current) {
      await getSessionIdHandle()
    }

    const newChatItem = createChatItem({
      user: userInput,
      assistant: '',
    })

    changeListPush(newChatItem)
    changeIsInput(true)
    changeUserInput('')
    smoothScrollTo(cacheDom.listContainerRef, cacheDom.listContainerRef.scrollHeight + 9999)

    cacheDom.listContainerRef._isScroll = true
    cacheDom.listContainerRef._prevScrollTop = 0

    // 开始打字
    streamTypewritingTool.typewriting()

    // 监听打字
    streamTypewritingTool.config.streamCallback = (text: string) => {
      changeCurrentChatItem(text)
      // 页面滚动
      scrollToBottom()
    }
    // 监听结束
    streamTypewritingTool.config.endCallback = () => {
      // 打完字解放禁止操作状态
      changeIsInput(false)
    }

    try {
      const resp = await fetchPostStream2({
        data: {
          context,
          question: userInput,
          session_id: sessionId.current,
        },
        onDownloadProgress: (e: any) => {
          analysisStreamApi.analysisData2(e, (data: any) => {
            // console.log('data: ', data)
            // 判断是否结束
            if (data === '[DONE]') {
              // changeIsInput(false)
              changeChatItemDone(newChatItem)
              changeContext()
              return
            }

            const msg = data?.choices[0]?.delta?.content
            if (msg !== void 0) {
              // changeCurrentChatItem(msg)
              // 滚动
              // scrollToBottom()
              streamTypewritingTool.config.tempChatAssistant += msg
            }
          })
        },
      })
      if (resp.status === 500) {
        // changeIsInput(false)
        changeCurrentChatItem('请先登录再操作哦')
        changeLoginSwitch(true)
      }
    } catch (error) {
      console.log('error: ', error)
      // changeIsInput(false)
      changeCurrentChatItem('很抱歉，网络连接超时，请检查您的网络设置后再试。')
    } finally {
      fetchUserInfo({}, false)
    }
    // 结束打字
    streamTypewritingTool.endTypewriting()
    // scrollToBottom()
  }

  const inputKeyDownHandle = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      submitPostStream()
    }
    return true
  }

  // 是否禁用重置按钮
  const isDisabledResetSession = isInput || context.length === 0
  const resetSessionHandle = () => {
    if (isDisabledResetSession) {
      return
    }
    clearContext()
    changeListPush({
      isResetSession: true,
      id: Date.now(),
    })
    scrollToBottom()
  }

  return (
    <div className={classNames([CSS.input_box])}>
      <div className={CSS.input_content}>
        <div className={CSS.input_box_top}>
          <div className={CSS.input_top_left}></div>
          <div className={CSS.input_top_right}>
            <MemberInfo />
          </div>
        </div>
        <div className={CSS.input_box_bottom}>
          <Tooltip placement="top" title={<span>重置对话</span>}>
            <div
              className={classNames([
                CSS.input_box_realod,
                'filex_center',
                isDisabledResetSession ? 'cursor_not_allowed' : 'cursor_pointer',
              ])}
              onClick={resetSessionHandle}
            >
              <Reload2 />
            </div>
          </Tooltip>
          <div className={CSS.input_box_right}>
            {/* <textarea
              className="scrollbar_style2"
              placeholder={
                isInput ? '请耐心等待AI把话说完...' : '输入你想问的问题，Enter发送，Shift+Enter换行'
              }
              value={userInput}
              onChange={e => changeUserInput(e.target.value)}
              onKeyDown={inputKeyDownHandle}
            ></textarea> */}
            <Input.TextArea
              className="scrollbar_style"
              placeholder={
                isInput ? '请耐心等待AI把话说完...' : '输入你想问的问题，Enter发送，Shift+Enter换行'
              }
              autoSize={{ minRows: 1, maxRows: 3 }}
              value={userInput}
              onChange={e => changeUserInput(e.target.value)}
              onKeyDown={inputKeyDownHandle}
            />
            <div className={classNames([CSS.input_box_right_btn_box, 'filex_align_center'])}>
              {/* <div className="filex_align_center">
                <MicrophoneIcon1 />
              </div> */}
              <div className="filex_align_center">
                <Button
                  type="primary"
                  onClick={submitPostStream}
                  icon={<Aeroplane />}
                  disabled={isInput || !userInput.trim()}
                >
                  发送
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default InputContainer
