import React, { memo } from 'react'

const DefaultAvatar = memo((props: any) => {
  const { color = '#CBD5E7' } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_3714_320)">
        <path
          d="M31.9979 16.0164C31.9979 24.8431 24.8344 31.9999 15.9973 31.9999C7.16095 31.9999 0 24.8431 0 16.0164C0 9.24125 4.21709 3.4529 10.1728 1.12669C11.9723 0.400769 13.9382 -0.00012207 15.9977 -0.00012207C18.0597 -0.00012207 20.0239 0.400364 21.8251 1.12669C27.7804 3.4529 31.9979 9.24129 31.9979 16.0164ZM9.8482 12.6456C9.8482 15.3392 11.2283 17.6712 13.2345 18.8127C14.0665 19.2862 15.0034 19.5592 15.9973 19.5592C16.9563 19.5592 17.8605 19.3038 18.6707 18.8626C20.7243 17.7429 22.1489 15.3824 22.1489 12.6461C22.1489 8.83617 19.3899 5.73502 15.9973 5.73502C12.6067 5.73462 9.8482 8.8358 9.8482 12.6456ZM16.0623 30.9641C20.7365 30.9641 24.9061 28.8145 27.6391 25.4504C27.3162 22.7967 24.7304 20.5376 21.0707 19.4108C19.6885 20.6542 17.9226 21.4023 15.9968 21.4023C14.0284 21.4023 12.2264 20.6139 10.8308 19.3164C7.2708 20.3325 4.66324 22.405 4.05185 24.8884C6.76381 28.5725 11.1348 30.9641 16.0623 30.9641Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3714_320">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
})

export default DefaultAvatar
