import React, { ChangeEvent, memo, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { Empty, Popconfirm } from 'antd'
import useChatToolStore from '@/store/chatTool'
import CSS from './left.module.scss'
import AIQLogo from '@/assets/images/logo.png'
import AIQText from '@/assets/images/ChatAIQ.png'
import CloseIcon from '@/assets/svg/Close'
import Close2 from '@/assets/svg/Close2'
import CacheComponent from '@/components/CacheComponent'

const entryList = [
  {
    text: 'ChatAIQ',
    path: '/chat',
    icon: require('@/assets/images/icons/liaotian1.png'),
    iconActivated: require('@/assets/images/icons/liaotian2.png'),
  },
  {
    text: '助理大厅',
    path: '/assistant',
    icon: require('@/assets/images/icons/zhuli1.png'),
    iconActivated: require('@/assets/images/icons/zhuli2.png'),
  },
  {
    text: '内容创作',
    path: '/creation',
    icon: require('@/assets/images/icons/chuangzuo1.png'),
    iconActivated: require('@/assets/images/icons/chuangzuo2.png'),
  },
]

const Left = memo(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const [entryIndex, setEntryIndex] = useState(0)
  const [sessionIndex, setSessionIndex] = useState(-1)
  const sessionListRef = useRef<HTMLDivElement | null>(null)
  const { sessionList, deleteSessionItem, fetchController } = useChatToolStore(state => ({
    sessionList: state.sessionList,
    deleteSessionItem: state.deleteSessionItem,
    fetchController: state.fetchController,
  }))
  const assistantItem = location.state?.assistantItem

  useEffect(() => {
    // 默认选中
    const entryIndex = entryList.findIndex(v => v.path === location.pathname)
    setEntryIndex(entryIndex)
    if (location.pathname === '/chat-tool' && entryIndex !== 0) {
      let index = sessionList.findIndex((v: any) => v.unique_id === assistantItem?.unique_id)
      if (index === -1) {
        index = 0
      }
      setSessionIndex(index)
    }
  }, [location.pathname])

  useEffect(() => {
    setTimeout(() => {
      const sessionActivateItemEl = sessionListRef.current?.querySelector(
        `.${CSS.session_activate_item}`
      ) as HTMLDivElement
      if (sessionActivateItemEl) {
        sessionActivateItemEl.scrollIntoView()
      }
    }, 20)
  }, [])

  const entryHandle = (item: any, index: number) => {
    if (item.path === location.pathname) {
      return
    }
    if (index === 0) {
      setSessionIndex(-1)
    }
    setEntryIndex(index)
    navigate(item.path)
  }
  const sessionHandle = (index: number, item: any) => {
    if (index === sessionIndex) {
      return
    }
    setSessionIndex(index)
    navigate('/chat-tool', {
      state: {
        assistantItem: item,
      },
    })
  }
  const closeSessionItemClick = (item: any) => {
    // e.stopPropagation()
    deleteSessionItem(item.unique_id)
    if (assistantItem?.unique_id === item.unique_id) {
      setSessionIndex(-1)
      fetchController?.abort?.()
      navigate('/chat')
    }
    // if (sessionList.length === 1) {
    //   navigate('/chat')
    // }
  }
  const closeAllSessionItem = () => {
    deleteSessionItem('all')
    navigate('/chat')
    fetchController?.abort?.()
  }
  const jumpPage = (url: string) => {
    if (url !== location.pathname) {
      navigate(url)
    }
  }
  return (
    <div className={CSS.wrapper}>
      {/* logo */}
      <div className={CSS.logo_box}>
        <div className={CSS.aiq_logo}>
          <img src={AIQLogo} alt="logo" />
        </div>
        <div className={CSS.aiq_logo_text}>
          <img src={AIQText} alt="text" />
        </div>
      </div>
      {/* 功能入口 */}
      <div className={CSS.entry_box}>
        {entryList.map((v, i) => {
          return (
            <div
              key={v.text}
              className={classNames([CSS.entry_item, i === entryIndex && CSS.entry_activate_item])}
              onClick={() => entryHandle(v, i)}
            >
              <img src={i === entryIndex ? v.iconActivated : v.icon} alt="icon" />
              <span className="ml-3">{v.text}</span>
            </div>
          )
        })}
      </div>
      {/* 对话列表 */}
      <div className={classNames([CSS.session_box, 'none_scrollbar'])}>
        <div className={CSS.session_title_box}>
          <span>对话列表</span>
        </div>
        <div className={classNames([CSS.session_list, 'scrollbar_style'])} ref={sessionListRef}>
          <CacheComponent show={sessionList.length === 0}>
            <div className={CSS.empty_box}>
              <img
                className={CSS.empty_img}
                src={require('@/assets/images/empty.png')}
                alt="empty"
              />
              <span className={CSS.empty_text}>暂无对话</span>
              <div className={CSS.empty_btn} onClick={() => jumpPage('/assistant')}>
                前往助理大厅
              </div>
            </div>
          </CacheComponent>
          {sessionList.map((v: any, i) => {
            return (
              <div
                key={v.unique_id}
                className={classNames([
                  CSS.session_item,
                  sessionIndex === i && CSS.session_activate_item,
                  'justify-between',
                  'items-center',
                ])}
                onClick={() => sessionHandle(i, v)}
              >
                <div className="flex">
                  <div className={CSS.session_item_left}>
                    <img src={v.tag.icon} alt="img" />
                  </div>
                  <div className={CSS.session_item_right}>
                    <div className={CSS.session_item_title}>{v.title}</div>
                  </div>
                </div>
                <div onClick={(e: any) => e.stopPropagation()}>
                  <Popconfirm
                    placement="right"
                    title="是否删除该对话？"
                    onConfirm={() => closeSessionItemClick(v)}
                    okText="确定"
                    cancelText="取消"
                  >
                    <div
                      className={classNames(['flex justify-end', CSS.item_close])}
                      onClick={(e: any) => e.stopPropagation()}
                    >
                      <CloseIcon />
                    </div>
                  </Popconfirm>
                </div>
              </div>
            )
          })}
        </div>
        {sessionList.length > 0 && (
          <div className={CSS.close_box}>
            <Popconfirm
              placement="top"
              title="是否删除全部对话？"
              onConfirm={closeAllSessionItem}
              okText="确定"
              cancelText="取消"
            >
              <div className={CSS.close_all}>
                <Close2 />
                <span>清空所有对话</span>
              </div>
            </Popconfirm>
          </div>
        )}
      </div>
    </div>
  )
})

export default Left
