import React, { memo, useEffect, Fragment } from 'react'
import CSS from './index.module.scss'
import classNames from 'classnames'
import MsgItem from '../MsgItem'
import { smoothScrollTo } from '@/utils'
import { Divider } from 'antd'
import { useLocation } from 'react-router-dom'
import useChatToolStore from '@/store/chatTool'
const listContainer = memo((props: any) => {
  const location = useLocation()
  // const chatList = useChatToolStore(state => state.list)
  const sessionDatas = useChatToolStore(state => state.sessionDatas)
  const assistantItem = location.state?.assistantItem
  // 如果有缓存就拿缓存的
  const currentSessionDataList = (sessionDatas as any)[assistantItem?.unique_id] ?? []
  // console.log('list currentSessionDataList: ', currentSessionDataList)
  useEffect(() => {
    if (props.listContainerRef) {
      props.listContainerRef._prevScrollTop = 0
      smoothScrollTo(props.listContainerRef, props.listContainerRef.scrollHeight + 9999)
    }
  }, [props.listContainerRef])
  // 监听滚动事件
  const scrollHandle = (e: any) => {
    const { scrollTop, scrollHeight, offsetHeight } = e.target
    if (scrollTop < scrollHeight - offsetHeight) {
      // console.log('上')
      e.target._isScroll = false
    } else {
      // console.log('底')
      e.target._isScroll = true
    }
    e.target._prevScrollTop = scrollTop
  }
  return (
    <div
      className={classNames([CSS.wrapper, 'scrollbar_style'])}
      ref={el => props.setListContainerRef(el)}
      onScroll={scrollHandle}
    >
      <div className={CSS.list_content}>
        {currentSessionDataList.map((v: any, i: number) => {
          // <Divider key={v.id}>对话重置</Divider>
          return (
            <Fragment key={v.id}>
              {(v as any).isResetSession ? (
                <Divider key={v.id} className={CSS.divider}>
                  对话重置
                </Divider>
              ) : (
                <MsgItem key={v.id} item={v} index={i} />
              )}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
})

export default listContainer
