import useUserStore from '@/store/user'
import React, { memo, useEffect, useRef, useState } from 'react'
import { Empty, Input, Modal, Popconfirm } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import CSS from './index.module.scss'
import CopyIcon2 from '@/assets/svg/CopyIcon2'
import DeleteIcon from '@/assets/svg/DeleteIcon'
import { getHistoryList, deleteHistory } from '@/api/user'
import { useRequest } from '@/hooks/useRequest'
import { copyTool, notificationTool } from '@/utils/tool'
import { debounce } from '@/utils'
import moment from 'moment'
import classNames from 'classnames'
import EmptyIcon from '@/assets/svg/EmptyIcon'

const History = memo(() => {
  const [historyList, setHistoryList] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const searchValRef = useRef(searchVal)
  searchValRef.current = searchVal
  // console.log('searchVal: ', searchVal)
  const hisListReq = useRequest(getHistoryList)
  const delHisReq = useRequest(deleteHistory)
  const { isShowHistory, changeIsShowHistory } = useUserStore(state => ({
    isShowHistory: state.isShowHistory,
    changeIsShowHistory: state.changeIsShowHistory,
  }))

  useEffect(() => {
    getHistoryListToolFn()
  }, [searchVal])

  useEffect(() => {
    getHistoryListToolFn()
  }, [])

  useEffect(() => {
    if (isShowHistory) {
      getHistoryListToolFn()
    }
  }, [isShowHistory])

  const getHistoryListToolFn = React.useRef(
    debounce(() => {
      hisListReq.run(
        {
          title: searchValRef.current,
        },
        {
          onSuccess(res) {
            // console.log('res: ', res)
            if (res.data) {
              setHistoryList(res.data)
            }
          },
          onError(err) {
            console.log('err: ', err)
          },
        },
        {
          code: 1,
        }
      )
    }, 500)
  ).current

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.target.value)
  }

  const delConfirmHandle = (id: number) => {
    delHisReq.run(
      {
        id,
      },
      {
        onSuccess(res) {
          if (res.code === 1) {
            notificationTool(res.msg)
            setHistoryList(state => state.filter((v: any) => v.id !== id))
          } else {
            notificationTool(res.msg, 'error')
          }
        },
        onError(err) {
          console.log('err: ', err)
          notificationTool('网络出错~', 'error')
        },
      },
      {
        code: 1,
      }
    )
  }

  return (
    <Modal
      wrapClassName={CSS.my_modal_wrapper}
      open={isShowHistory}
      title="历史记录"
      style={{ top: 100 }}
      footer={null}
      onOk={() => changeIsShowHistory(false)}
      onCancel={() => changeIsShowHistory(false)}
    >
      <div className={CSS.history_box}>
        <div className={CSS.his_input_box}>
          <Input
            size="large"
            placeholder="请输入关键词"
            prefix={<SearchOutlined />}
            value={searchVal}
            onChange={handleChange}
          />
        </div>
        <div className={classNames([CSS.his_list, 'scrollbar_style'])}>
          {historyList.map((v: any, index: number) => {
            return (
              <div key={v.id} className={CSS.his_list_item}>
                <div className={CSS.his_item_title}>{v.question}</div>
                <div className={CSS.his_item_ai}>{v.answer}</div>
                <div className={CSS.his_item_tool}>
                  <div className={CSS.his_item_left}>
                    <span className={CSS.his_item_left_time}>
                      {moment.unix(v.createtime).format('YYYY/MM/DD HH:mm:ss')}
                    </span>
                  </div>
                  <div className={CSS.his_item_right}>
                    <div
                      className={CSS.icon}
                      onClick={() => copyTool(v.answer)}
                      onMouseEnter={() => {
                        v.isShowCopy = true
                        setHistoryList(state => {
                          ;(state as any[])[index] = { ...v }
                          return [...state]
                        })
                      }}
                      onMouseLeave={() => {
                        v.isShowCopy = false
                        setHistoryList(state => {
                          ;(state as any[])[index] = { ...v }
                          return [...state]
                        })
                      }}
                    >
                      <CopyIcon2 color={v.isShowCopy ? '#202020' : '#666666'} />
                    </div>
                    <Popconfirm
                      placement="top"
                      title={'确定删除该记录吗？'}
                      onConfirm={() => delConfirmHandle(v.id)}
                      okText="确定"
                      cancelText="取消"
                    >
                      <div
                        className={CSS.icon}
                        onMouseEnter={() => {
                          v.isShowDel = true
                          setHistoryList(state => {
                            ;(state as any[])[index] = { ...v }
                            return [...state]
                          })
                        }}
                        onMouseLeave={() => {
                          v.isShowDel = false
                          setHistoryList(state => {
                            ;(state as any[])[index] = { ...v }
                            return [...state]
                          })
                        }}
                      >
                        <DeleteIcon color={v.isShowDel ? '#E74E4E' : '#666666'} />
                      </div>
                    </Popconfirm>
                  </div>
                </div>
              </div>
            )
          })}
          {historyList.length === 0 && (
            <div className={CSS.empty_box}>
              <Empty
                image={<EmptyIcon />}
                imageStyle={{
                  height: 163,
                }}
                description={<div className="mt-10 color-text-note text-base">暂无历史记录</div>}
              ></Empty>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
})

export default History
