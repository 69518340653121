import React, { memo, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CSS from './index.module.scss'
import Header from '@/components/Header'
import classNames from 'classnames'
import * as chatAPI from '@/api/assistant'
import { useRequest } from '@/hooks/useRequest'
import useChatStore from '@/store/assistant'
import ArrowsRight1 from '@/assets/svg/ArrowsRight1'
import useChatToolStore from '@/store/chatTool'
import useIndexStore from '@/store'
import { message } from 'antd'

const Assistant = memo(() => {
  const navigate = useNavigate()
  const { tabs, exampleList, tabIndex, changeAssistantCurrentIndex, changeFilterExampleList } =
    useChatStore()
  const clearList = useChatToolStore(state => state.clearList)
  const changeSessionList = useChatToolStore(state => state.changeSessionList)
  const sessionList = useChatToolStore(state => state.sessionList)
  const filterContentRef = useRef<HTMLDivElement>(null)
  const token = useIndexStore(state => state.token)
  const changeLoginSwitch = useIndexStore(state => state.changeLoginSwitch)

  useEffect(() => {
    filterContentRef.current?.style.setProperty(
      '--filter-offsettop',
      filterContentRef.current?.offsetTop + 'px'
    )
  }, [exampleList])

  const filterTabItemHandle = (item: any, index: number) => {
    changeAssistantCurrentIndex(index)
    changeFilterExampleList(item.unique_id)
  }

  const filterContentItemHandle = (item: any, index: number) => {
    if (!token) {
      message.warning('请先登录~')
      changeLoginSwitch(true)
      return
    }
    // console.log('item: ', item)
    // changeAssistantCurrentIndex(index)
    const find = sessionList.find((v: any) => v.unique_id === item.unique_id)
    if (!find) {
      changeSessionList(item)
    }
    clearList()
    navigate('/chat-tool', {
      state: {
        assistantItem: item,
      },
    })
  }

  const headerCenter = <div className="header_title_text">助理大厅</div>
  return (
    <div className={CSS.wrapper}>
      <Header isBack center={headerCenter} />
      <div className={classNames([CSS.content, 'window_content_width'])}>
        <div className={CSS.filter_box}>
          {tabs.map((v: any, i) => {
            return (
              <div
                key={v.name}
                className={classNames([
                  CSS.filter_item,
                  i === tabIndex && CSS.activate_filter_item,
                ])}
                onClick={() => filterTabItemHandle(v, i)}
              >
                <span>{v.name}</span>
              </div>
            )
          })}
        </div>
        <div className={classNames([CSS.filter_content_scroll, 'scrollbar_style'])}>
          <div className={classNames([CSS.filter_content])} ref={filterContentRef}>
            {exampleList.map((v: any, i) => {
              return (
                <div
                  key={v.unique_id}
                  className={CSS.filter_con_item}
                  onClick={() => filterContentItemHandle(v, i)}
                >
                  <div className={CSS.filter_con_item_left}>
                    <img className={CSS.filter_con_i_l_icon} src={v.tag.icon} alt="img" />
                  </div>
                  <div className={CSS.filter_con_item_right}>
                    <div className={CSS.filter_c_i_r_top}>
                      <span className={CSS.f_c_i_r_t_text}>{v.title}</span>
                      <div className={CSS.f_c_i_r_t_icon}>
                        <ArrowsRight1 />
                      </div>
                    </div>
                    <div className={CSS.filter_c_i_r_bottom}>
                      <div className={CSS.f_c_i_r_b_text}>{v.intro}</div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
})

export default Assistant
