import React, { memo, useState } from 'react'
import CSS from './index.module.scss'
import Input from '@/ui/Input'
import passwordNoImg from '@/assets/images/icons/password-no.png'
import passwordVisibleImg from '@/assets/images/icons/password-visible.png'

const PasswordLogin = memo((props: any = {}) => {
  const [toggle, setToggle] = useState(false)
  return (
    <div>
      <div className="mt-7">
        <Input
          type="number"
          placeholder="请输入手机号"
          maxLength={11}
          value={props.loginFormData.mobile}
          onChange={(e: any) => props.onChange?.(e.target.value, 'mobile')}
        />
      </div>
      <div className="mt-3">
        <Input
          type={toggle ? 'text' : 'password'}
          placeholder="请输入密码"
          maxLength={18}
          value={props.loginFormData.password}
          onChange={(e: any) => props.onChange?.(e.target.value, 'password')}
          rightChild={
            <div className="cursor-pointer flex items-center" onClick={() => setToggle(!toggle)}>
              <img src={toggle ? passwordVisibleImg : passwordNoImg} alt="img" />
            </div>
          }
        />
      </div>
      <div className={CSS.retrieve_password}>
        <span onClick={() => props.onSetType?.(2)}>忘记密码？</span>
      </div>
    </div>
  )
})

export default PasswordLogin
