import { useRef } from 'react'

export default function useStreamTypewriting() {
  const config = useRef<{
    tempChatAssistant: string
    streamTypewritingTimeId: null | NodeJS.Timer
    speed: number
    status: string
    streamCallback: null | ((text: string) => void)
    endCallback: null | (() => void)
  }>({
    tempChatAssistant: '',
    streamTypewritingTimeId: null,
    speed: 17,
    status: 'start', // start\done
    streamCallback: null,
    endCallback: null,
  })

  const initConfig = () => {
    const { streamTypewritingTimeId } = config.current
    clearInterval(streamTypewritingTimeId as NodeJS.Timer)
    config.current.streamTypewritingTimeId = null
    config.current.tempChatAssistant = ''
    config.current.status = 'start'
  }

  const typewriting = () => {
    let index = 0
    config.current.streamTypewritingTimeId = setInterval(() => {
      const { tempChatAssistant, streamTypewritingTimeId, status, streamCallback, endCallback } =
        config.current
      if (status === 'done' && index >= tempChatAssistant.length) {
        initConfig()
        endCallback?.()
        return
      }
      if (streamTypewritingTimeId === null) return
      if (index >= tempChatAssistant.length) return

      streamCallback?.(tempChatAssistant[index])
      index++
    }, config.current.speed)
  }

  const endTypewriting = () => {
    config.current.status = 'done'
  }

  return {
    config: config.current,
    typewriting,
    endTypewriting,
  }
}
