import { create } from 'zustand'

import { message } from 'antd'
import * as chatAPI from '@/api/assistant'
import { persist } from 'zustand/middleware'

const store = (set: any, get: any) => ({
  tabIndex: 0,
  tabs: [],
  allExampleList: [],
  exampleList: [],
  changeFilterExampleList(id: number) {
    const { allExampleList } = get()
    set({
      exampleList:
        id === 0 ? allExampleList : allExampleList.filter((v: any) => v.category_id === id),
    })
  },
  changeAssistantCurrentIndex(tabIndex: number) {
    const { assistant } = get()
    set({
      tabIndex,
    })
  },
  async fetchSceneHallTabs() {
    try {
      const { fetchSceneExampleList } = get()
      const res: any = await chatAPI.getSceneHallTabs()
      if (res.code !== 200) {
        message.error(res.msg)
        return
      }

      await fetchSceneExampleList(0)

      const all = {
        name: '全部',
        unique_id: 0,
      }
      set({
        tabIndex: 0,
        tabs: [all, ...res.data.list],
      })
    } catch (error) {
      console.log('error: ', error)
    }
  },
  async fetchSceneExampleList(id: any) {
    try {
      const res: any = await chatAPI.getSceneExampleList(id)
      console.log('res: ', res)
      if (res.code !== 200) {
        message.error(res.msg)
        return
      }

      set({
        allExampleList: res.data.list,
        exampleList: res.data.list,
      })
    } catch (error) {
      console.log('error: ', error)
    }
  },
})

const useAssistantStore = create(persist(store, { name: 'assistant_state' }))

export default useAssistantStore
