import BJRequest from './request'
import { BASE_URL, TIME_OUT } from './request/config'
import useIndexStore from '@/store'
import NProgress from '@/config/nprogress'
import { getParameterByName } from '@/utils'

const bjRequest = new BJRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  hooks: {
    requestHook(config) {
      if ((config.headers as any).isLoad || (config.headers as any).isLoad === void 0) {
        NProgress.start()
      }
      // console.log('config-----------: ', config)
      const token = useIndexStore.getState().token
      if (token && config.headers) {
        config.headers.token = token
      }
      if (config.headers) {
        config.headers.ctype = 'chatpc_1'
      }
      const channel_no = getParameterByName('channel_no')
      if (config.headers && channel_no) {
        config.headers['x-channel-no'] = channel_no
      }
      return config
    },
    requestHookCatch(err) {
      return err
    },
    responseHook(config) {
      NProgress.done()
      return config
    },
    responseHookCatch(err) {
      NProgress.done()
      return err
    },
  },
})

export { bjRequest }
