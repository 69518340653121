import React, { memo, useEffect, useState } from 'react'

import CSS from './index.module.scss'
import Header from '@/components/Header'
import classNames from 'classnames'
import InputContainer from './cpns/InputContainer'
import ListContainer from './cpns/ListContainer'

const Chat = memo(() => {
  const [listContainerRef, setListContainerRef] = useState<HTMLDivElement | null>(null)
  const headerCenter = <div className="header_title_text">ChatAIQ</div>
  return (
    <div className={CSS.wrapper}>
      <Header center={headerCenter} />
      <div className={classNames([CSS.content])}>
        <ListContainer
          listContainerRef={listContainerRef}
          setListContainerRef={setListContainerRef}
        />
        <InputContainer listContainerRef={listContainerRef} />
      </div>
    </div>
  )
})

export default Chat
