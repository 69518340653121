import React, { memo } from 'react'

const ArrowsRight1 = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
      <path d="M1 0.5L5.5 5L1 9.5" stroke="#202020" strokeWidth="1.2" />
    </svg>
  )
})

export default ArrowsRight1
