import React, { memo } from 'react'

const MsgIcon1 = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M12.8667 1.73334H3.13337C2.13337 1.73334 1.33337 2.53334 1.33337 3.53334V10.6C1.33337 11.6 2.13337 12.4667 3.13337 12.4667H4.66671L5.33337 14.0667C5.46671 14.4 5.93337 14.4667 6.13337 14.1333L7.33337 12.4667H12.8C13.8 12.4667 14.6 11.6667 14.6 10.6V3.53334C14.6667 2.53334 13.8667 1.73334 12.8667 1.73334ZM4.20004 8.06667C3.73337 8.06667 3.40004 7.66667 3.40004 7.2C3.40004 6.73334 3.80004 6.33334 4.20004 6.33334C4.60004 6.33334 5.00004 6.73334 5.00004 7.2C5.00004 7.73334 4.66671 8.06667 4.20004 8.06667ZM8.00004 8.06667C7.53337 8.06667 7.20004 7.66667 7.20004 7.2C7.20004 6.73334 7.60004 6.33334 8.00004 6.33334C8.40004 6.33334 8.80004 6.73334 8.80004 7.2C8.80004 7.73334 8.46671 8.06667 8.00004 8.06667ZM11.8 8.06667C11.3334 8.06667 11 7.66667 11 7.2C11 6.73334 11.4 6.33334 11.8 6.33334C12.2 6.33334 12.6 6.73334 12.6 7.2C12.6667 7.73334 12.2667 8.06667 11.8 8.06667Z"
        fill="#4A5D82"
      />
    </svg>
  )
})

export default MsgIcon1
