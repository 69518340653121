interface IAntdUI {
  messageApi: any
  notificationApi: any
}

const antdUI: IAntdUI = {
  messageApi: null,
  notificationApi: null,
}

export default antdUI
