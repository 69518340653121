import antdUI from '@/antd-ui'

/**
 * 节流工具函数
 * @param cb 需要节流的函数
 * @param delay 执行时间（默认100毫秒）
 * @returns 新函数
 */
export function throttle(cb: (...arg: any[]) => any, delay = 100) {
  let timeID: null | NodeJS.Timeout = null
  return (...arg: any[]) => {
    if (timeID !== null) return

    timeID = setTimeout(() => {
      cb?.(...arg)
      timeID = null
    }, delay)
  }
}

/**
 * 防抖
 */
export function debounce<T extends any[]>(func: (...args: T) => void, delay: number) {
  let timerId: NodeJS.Timeout
  return (...args: T) => {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      func.apply(null, args)
    }, delay)
  }
}

/**
 * 优化滚动
 * @param element 滚动的元素
 * @param to 滚动数值
 */
export function smoothScrollTo(element: any, to: any) {
  const start = element.scrollTop
  const change = to - start
  let currentTime = 0
  const increment = 20

  function animateScroll() {
    currentTime += increment
    const val = easeInOutQuad(currentTime, start, change, 100)
    element.scrollTop = val
    if (currentTime < 100) {
      requestAnimationFrame(animateScroll)
    }
  }

  animateScroll()
}

function easeInOutQuad(t: any, b: any, c: any, d: any) {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

/**
 * 帮忙网络请求
 */
export function awaitToRequest<T, U = Error>(
  promise: Promise<T>,
  errorExt?: object,
  params: any = {}
): Promise<[U, undefined] | [null | object, T]> {
  return promise
    .then<[null | object, T]>((res: any) => {
      const code = params?.code ?? 200
      if (res?.code !== code) {
        if (!params.noToastCodes?.includes?.(res.code)) {
          antdUI.messageApi.open({
            type: 'error',
            content: res?.msg || '系统异常，请稍后重试',
          })
        }
        return [{}, res]
      }
      return [null, res]
    })
    .catch<[U, undefined]>((err: U) => {
      antdUI.messageApi.open({
        type: 'error',
        content: '网络异常，请稍后重试',
      })
      if (errorExt) {
        const parsedError = Object.assign({}, err, errorExt)
        return [parsedError, undefined]
      }
      return [err, undefined]
    })
}
/**
 * 校验手机号
 */
export function verifyMobile(mobile: string) {
  return /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(mobile)
}

/**
 * 根据name获取浏览器url参数
 */
export function getParameterByName(name: string, url?: string) {
  if (!url) url = window.location.href
  name = name.replace(/[\\[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}
