import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const state: {
  fetchController: null | AbortController
} = {
  fetchController: null,
}

const store = (set: any, get: any) => ({
  userInput: '',
  isInput: false,
  currentChatItem: null,
  context: [],
  maxCtxNum: 4,
  retrySessionChatItem: null,
  sessionList: [],
  sessionDatas: {},
  contextDatas: {},
  // fetchController: null,
  ...state,
  changeFetchController(fetchController: null | AbortController) {
    set({ fetchController })
  },
  changeList(newList: any, unique_id: number) {
    const { sessionDatas } = get()
    set({
      sessionDatas: {
        ...sessionDatas,
        [unique_id]: newList,
      },
    })
  },
  changeSessionList(item: any) {
    const { sessionList } = get()
    set({
      sessionList: [item, ...sessionList],
    })
  },
  deleteSessionItem(unique_id: number | string) {
    const { sessionList, sessionDatas } = get()
    if (unique_id === 'all') {
      set({
        sessionList: [],
        sessionDatas: {},
      })
      return
    }
    const { [unique_id]: _, ...newSessionDatas } = sessionDatas
    set({
      sessionList: sessionList.filter((v: any) => v.unique_id !== unique_id),
      sessionDatas: newSessionDatas,
    })
  },
  changeRetrySessionChatItem(retrySessionChatItem: any) {
    set({
      retrySessionChatItem,
    })
  },
  clearContext() {
    const { context } = get()
    set({
      context: [context[0]],
    })
  },
  initContext(unique_id: number, initState?: any[]) {
    // console.log('initState: ', initState)
    const { getContext } = get()

    const newContext = Array.isArray(initState) ? initState : getContext(unique_id)
    // console.log('newContext: ', newContext)
    set({
      context: newContext,
    })
  },
  getContext(unique_id: number) {
    const { sessionDatas } = get()
    const list = sessionDatas[unique_id]
    const [_, firstItem, ...lastThreeItems] = list
    // console.log('firstItem: ', firstItem)
    const lastCtxItems = []
    for (let i = lastThreeItems.length - 1; i >= 0; i--) {
      const item = lastThreeItems[i]
      if (!item.isResetSession && lastCtxItems.length < 2) {
        lastCtxItems.push(item)
      } else {
        break
      }
    }
    return [firstItem, ...lastCtxItems.reverse()].filter(Boolean)
  },
  changeContext(newChatItem: any) {
    const { sessionDatas, maxCtxNum, context } = get()
    const list = sessionDatas[newChatItem.unique_id]

    if (context.length > maxCtxNum) {
      context.splice(1, 1)
    }

    const last = list.at(-1)
    // 如果是重置项就直接返回 不添加到上下文中
    if (last.isResetSession) {
      return
    }
    set({
      context: context.concat(list.at(-1)),
    })
  },
  changeCurrentChatItemMsg(unique_id: number, msg: any) {
    const { sessionDatas } = get()
    const list = sessionDatas[unique_id]
    // console.log('list: ', list)

    const currentItem = list.pop()
    // console.log('currentItem: ', currentItem)
    currentItem.chat.assistant += msg
    set({
      sessionDatas: {
        ...sessionDatas,
        [unique_id]: [...list, { ...currentItem }],
      },
    })
  },
  changeChatItemDone(item: any) {
    const { sessionDatas } = get()
    const list = sessionDatas[item.unique_id]
    const index = list.findIndex((v: any) => v.id === item.id)
    const currentItem = list[index]
    // console.log('currentItem: ', currentItem)
    if (currentItem) {
      currentItem.done = true
      list[index] = { ...currentItem }
      set({
        sessionDatas: {
          ...sessionDatas,
          [item.unique_id]: [...list],
        },
      })
    }
  },
  changeChatItemIsLove(item: any, isLove: number) {
    const { sessionDatas } = get()
    const list = sessionDatas[item.unique_id]
    const index = list.findIndex((v: any) => v.id === item.id)
    const currentItem = list[index]
    currentItem.isLove = isLove
    list[index] = { ...currentItem }
    set({
      sessionDatas: {
        ...sessionDatas,
        [item.unique_id]: [...list],
      },
    })
  },
  deleteChatItem(item: any) {
    const { context, list, sessionDatas } = get()
    // 拿到当前列表的某个list数据，把里面的item删除掉
    const currentSessionData = sessionDatas[item.unique_id]

    if (currentSessionData) {
      const newSessionDataArr = currentSessionData.filter((v: any) => v.id !== item.id)
      sessionDatas[item.unique_id] = newSessionDataArr
    }

    set({
      sessionDatas: { ...sessionDatas },
      context: context.filter((v: any) => v.id !== item.id),
      list: list.filter((v: any) => v.id !== item.id),
    })
  },
  changeListPush(item: any) {
    const { sessionDatas } = get()

    // 如果有就添加一个，没有就直接创建一个数组把item丢进去
    const newSessionData = sessionDatas[item.unique_id]
      ? sessionDatas[item.unique_id].concat(item)
      : [item]

    set({
      sessionDatas: {
        ...sessionDatas,
        [item.unique_id]: newSessionData,
      },
    })
  },
  clearList() {
    set({
      list: [],
    })
  },
  changeIsInput(isInput: boolean) {
    set({
      isInput,
    })
  },
  changeUserInput(userInput: string) {
    set({
      userInput,
    })
  },
})

function partialize(state: any) {
  // 过滤字段
  const filterArr = ['isInput', 'fetchController']
  return Object.fromEntries(Object.entries(state).filter(([key]) => !filterArr.includes(key)))
}

const useChatToolStore = create(persist(store, { name: 'chat_tool', partialize }))

export default useChatToolStore
