/**
 * 开发环境：development
 * 生产环境：production
 * 测试环境：test
 */
let BASE_URL = ''
const TIME_OUT = 30000

const developmentBaseUrl = 'https://dev-a.aiq18.com'
const productionBaseUrl = 'https://a.aiq18.com'

// const developmentBaseUrl = 'http://dev-chatapi.aiq18.ai'
// const productionBaseUrl = 'http://chatapi.gzshibahao.com'

const packBaseUrl = developmentBaseUrl + '/api'
console.log('packBaseUrl---------: ', packBaseUrl)

if (process.env.NODE_ENV === 'development') {
  BASE_URL = '/proxy' // 代理
} else if (process.env.NODE_ENV === 'production') {
  BASE_URL = packBaseUrl
}

export { BASE_URL, TIME_OUT, developmentBaseUrl, productionBaseUrl, packBaseUrl }
