import { create } from 'zustand'
import { createChatItem } from '@/utils/chat'

const oneChatItem = createChatItem({
  isTool: false,
  created_at: '',
  assistant:
    '我是 ChatAIQ，一个能够进行聊天交互的人工智能助手。我可以帮助你回答各种问题，提供信息和建议，还可以陪伴你聊天，消除孤独。我在不断学习和改进自己的算法，以更好地为你提供服务。',
})

const store = (set: any, get: any) => ({
  list: [oneChatItem],
  userInput: '',
  isInput: false,
  currentChatItem: null,
  context: [],
  maxCtxNum: 4,
  retrySessionChatItem: null,
  itemInfo: {
    code: null,
    item_id: null,
    request_id: null,
  },
  changeItemInfo(itemInfo: any) {
    set({
      itemInfo,
    })
  },
  changeRetrySessionChatItem(retrySessionChatItem: any) {
    set({
      retrySessionChatItem,
    })
  },
  clearContext() {
    set({
      context: [],
    })
  },
  changeContext() {
    const { list, maxCtxNum, context } = get()
    if (context.length > maxCtxNum) {
      context.splice(1, 1)
    }
    set({
      context: context.concat(list.at(-1)),
    })
  },
  getContext() {
    const { list } = get()
    const [_, firstItem, ...lastThreeItems] = list
    return lastThreeItems.length === 0
      ? []
      : [firstItem, lastThreeItems.at(-4), lastThreeItems.at(-3), lastThreeItems.at(-2)].filter(
          Boolean
        )
  },
  changeCurrentChatItem(msg: any) {
    const { list } = get()
    const currentItem = list.pop()
    currentItem.chat.assistant += msg
    set({
      list: [...list, { ...currentItem }],
    })
  },
  changeChatItemDone(item: any) {
    const { list } = get()
    const index = list.findIndex((v: any) => v.id === item.id)
    const currentItem = list[index]
    currentItem.done = true
    list[index] = { ...currentItem }
    set({
      list: [...list],
    })
  },
  changeChatItemIsLove(item: any, isLove: number) {
    const { list } = get()
    const index = list.findIndex((v: any) => v.id === item.id)
    const currentItem = list[index]
    currentItem.isLove = isLove
    list[index] = { ...currentItem }
    set({
      list: [...list],
    })
  },
  deleteChatItem(item: any) {
    const { context, list } = get()
    set({
      context: context.filter((v: any) => v.id !== item.id),
      list: list.filter((v: any) => v.id !== item.id),
    })
  },
  changeListPush(item: any) {
    set({
      currentChatItem: item,
      list: [...get().list, item],
    })
  },
  changeIsInput(isInput: boolean) {
    set({
      isInput,
    })
  },
  changeUserInput(userInput: string) {
    set({
      userInput,
    })
  },
})

const useChatStore = create(store)

export default useChatStore
