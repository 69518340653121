import { bjRequest } from '..'

/**
 * 获取创作例子
 */
export function getCreateExampleList(type: string) {
  return bjRequest.post({
    url: '/api/Index/create',
    data: {
      type,
    },
  })
}
