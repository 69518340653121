import React, { memo } from 'react'

const ArrowsLeft1 = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="21" viewBox="0 0 10 21" fill="none">
      <path
        d="M1.91658 10.7708L6.91659 15.7708L8.08325 14.6041L4.24992 10.7708L8.08325 6.93748L6.91658 5.77081L1.91658 10.7708Z"
        fill="#4A5D82"
      />
    </svg>
  )
})

export default ArrowsLeft1
