import { bjRequest } from '../'

enum API {
  userdata = '/api/Personal/userdata',
  history = '/api/index/history',
  delHistory = '/api/index/delhistory',
  resetpwd = '/api/user/resetpwd',
  privacyPolicy = '/api/index/privacyPolicy',
  userAgree = '/api/index/userAgree',
  forgetpwd = '/api/user/forgetpwd',
}

/**
 * 用户协议
 */
export function getUserAgree(data = {}) {
  return bjRequest.get({
    url: API.userAgree,
    data,
  })
}

/**
 * 隐私协议
 */
export function getPrivacyPolicy(data = {}) {
  return bjRequest.get({
    url: API.privacyPolicy,
    data,
  })
}

/**
 * 重置密码
 */
export function resetpwd(data = {}) {
  return bjRequest.post({
    url: API.resetpwd,
    data,
  })
}

/**
 * 忘记密码
 */
export function forgetpwd(data = {}) {
  return bjRequest.post({
    url: API.forgetpwd,
    data,
  })
}

/**
 * 获取用户信息
 */
export function getUserInfo(data = {}, isLoad = true) {
  return bjRequest.post({
    url: API.userdata,
    data,
    headers: {
      isLoad,
    },
  })
}

/**
 * 获取历史记录
 */
export function getHistoryList(data = {}) {
  return bjRequest.post({
    url: API.history,
    data,
  })
}

/**
 * 删除历史记录
 */
export function deleteHistory(data = {}) {
  return bjRequest.post({
    url: API.delHistory,
    data,
  })
}
