import React, { memo } from 'react'
import CSS from './index.module.scss'
import classNames from 'classnames'
import MsgIcon1 from '@/assets/svg/MsgIcon1'
import TimeIcon2 from '@/assets/svg/TimeIcon2'
import useUserStore from '@/store/user'
import { GUIDANCE_PAY_TITLE } from '@/constant'
const MemberInfo = memo(() => {
  const userInfo: any = useUserStore(state => state.userInfo)
  const changeIsShowPayGuidance = useUserStore(state => state.changeIsShowPayGuidance)
  const itemHandle = () => {
    changeIsShowPayGuidance(true, GUIDANCE_PAY_TITLE)
  }
  return (
    <div className={CSS.wrapper}>
      <div className={classNames([CSS.vip_tool_item, 'filex_center'])} onClick={itemHandle}>
        <MsgIcon1 />
        {userInfo?.number === -1 ? (
          <span>活动期间不限次数</span>
        ) : (
          <span>剩余次数：{userInfo?.number ? userInfo?.number : 0}次</span>
        )}
      </div>
      <div className={classNames([CSS.vip_tool_item, 'filex_center'])} onClick={itemHandle}>
        <TimeIcon2 />
        <span>会员时长：{userInfo?.exceedtime ? userInfo?.exceedtime : 0}天</span>
      </div>
    </div>
  )
})

export default MemberInfo
