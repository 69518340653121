import React, { memo } from 'react'

const Aeroplane = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M13.813 2.07366C13.9603 2.1808 14.0206 2.32366 13.9938 2.50223L12.2796 12.7879C12.2572 12.9174 12.1858 13.0179 12.0653 13.0893C12.0028 13.125 11.9336 13.1429 11.8577 13.1429C11.8086 13.1429 11.755 13.1317 11.697 13.1094L8.66356 11.8705L7.04306 13.846C6.9627 13.9487 6.85333 14 6.71494 14C6.65691 14 6.6078 13.9911 6.56762 13.9732C6.4828 13.942 6.41472 13.8895 6.36338 13.8158C6.31205 13.7422 6.28638 13.6607 6.28638 13.5714V11.2344L12.072 4.14286L4.91364 10.3371L2.2686 9.25223C2.10342 9.18973 2.01414 9.06696 2.00074 8.88393C1.99182 8.70536 2.06324 8.57366 2.21503 8.48884L13.3577 2.06027C13.4246 2.02009 13.4961 2 13.572 2C13.6612 2 13.7416 2.02455 13.813 2.07366Z"
        fill="white"
      />
    </svg>
  )
})

export default Aeroplane
