import React, { memo } from 'react'

const Phone1 = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M11.3333 1.33334H4.66663C4.11434 1.33334 3.66663 1.78106 3.66663 2.33334V13.6667C3.66663 14.219 4.11434 14.6667 4.66663 14.6667H11.3333C11.8856 14.6667 12.3333 14.219 12.3333 13.6667V2.33334C12.3333 1.78106 11.8856 1.33334 11.3333 1.33334Z"
        stroke="#2D374C"
        strokeWidth="1.2"
      />
      <path
        d="M7.33337 3.33334H8.66671"
        stroke="#2D374C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66663 12.6667H9.33329"
        stroke="#2D374C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})

export default Phone1
