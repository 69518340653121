import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import useMenuStore from './menu'
import { mobileLogin, verifyToken } from '@/api/login'

const store = (set: any, get: any) => ({
  token: '',
  loginSwitch: false,
  changeLoginSwitch(loginSwitch: boolean) {
    set(() => ({
      loginSwitch,
    }))
  },
  setToken(token: string) {
    set(() => ({
      token,
    }))
  },
  async fetchVerifyToken() {
    const { token, setToken } = get()
    try {
      if (!token) {
        return false
      }
      const res: any = await verifyToken()
      if (res.code !== 200) {
        setToken('')
        return false
      }
      return true
    } catch (error) {
      return false
    }
  },
  async fetchMobileLogin(params: any) {
    try {
      const res = await mobileLogin(params)
      return res
    } catch (error) {
      return error
    }
  },
  async initializeState() {
    try {
      const res = await get().fetchVerifyToken()
      if (res) {
        await useMenuStore.getState().fetchMenuList()
      }
    } catch (error) {
      return error
    }
  },
})

function partialize(state: any) {
  // 过滤字段
  const filterArr = ['loginSwitch']
  return Object.fromEntries(Object.entries(state).filter(([key]) => !filterArr.includes(key)))
}

const useIndexStore = create(persist(store, { name: 'state', partialize }))

export default useIndexStore
