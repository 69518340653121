import React, { memo, useState, forwardRef, useImperativeHandle } from 'react'
import Input from '@/ui/Input'
import { useCaptcha } from '@/hooks/useCaptcha'
import { useRequest } from '@/hooks/useRequest'
import * as LoginAPI from '@/api/login'
import * as UserAPI from '@/api/user'
import antdUI from '@/antd-ui'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import CSS from './index.module.scss'
import passwordNoImg from '@/assets/images/icons/password-no.png'
import passwordVisibleImg from '@/assets/images/icons/password-visible.png'
import { verifyMobile } from '@/utils'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const RetrievePassword = forwardRef((props: any = {}, ref) => {
  const [formData, setFormData] = useState({
    mobile: '',
    captcha: '',
    password: '',
    check_password: '',
  })
  const [toggle, setToggle] = useState({
    password: false,
    check_password: false,
  })

  const loginCaptcha = useCaptcha()
  const captchaReq = useRequest(LoginAPI.getMobileCaptcha)

  const getCaptcha = () => {
    if (!verifyMobile(formData.mobile)) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请输入正确的手机号',
      })
      return
    }
    captchaReq.run(
      { mobile: formData.mobile, event: 'forgetpwd' },
      {
        onSuccess(res) {
          console.log('res: ', res)
          loginCaptcha.getCode()
        },
      },
      {
        code: 1,
      }
    )
  }

  const onChange = (value: string, type: string) => {
    setFormData({
      ...formData,
      [type]: value,
    })
  }

  const verifyState = () => {
    if (!verifyMobile(formData.mobile)) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请输入正确的手机号',
      })
      return
    }
    if (!formData.captcha.trim()) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请输入验证码',
      })
      return
    }
    if (formData.password !== formData.check_password) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '俩次输入的密码不一致',
      })
      return
    }
    if (formData.password.length < 6 || formData.check_password.length < 6) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请输入6-18位密码',
      })
      return
    }
    return true
  }

  useImperativeHandle(ref, () => ({
    verifyState,
    formData,
  }))

  return (
    <div>
      <div className="mt-6">
        <Input
          type="number"
          placeholder="请输入手机号"
          maxLength={11}
          value={formData.mobile}
          onChange={(e: any) => onChange(e.target.value, 'mobile')}
        />
      </div>
      <div className="mt-3">
        <Input
          type="number"
          placeholder="请输入验证码"
          maxLength={6}
          value={formData.captcha}
          onChange={(e: any) => onChange(e.target.value, 'captcha')}
          rightChild={
            captchaReq.loading ? (
              <Spin indicator={antIcon} />
            ) : loginCaptcha.isSetup ? (
              <div className={CSS.code_box}>{loginCaptcha.code}s</div>
            ) : (
              <div className={CSS.code_box} onClick={getCaptcha}>
                {loginCaptcha.isInit ? '重新获取' : '获取验证码'}
              </div>
            )
          }
        />
      </div>
      <div className="mt-3">
        <Input
          type={toggle.password ? 'text' : 'password'}
          placeholder="请输入密码"
          maxLength={18}
          value={formData.password}
          onChange={(e: any) => onChange(e.target.value, 'password')}
          rightChild={
            <div
              className="cursor-pointer flex items-center"
              onClick={() => setToggle({ ...toggle, password: !toggle.password })}
            >
              <img src={toggle.password ? passwordVisibleImg : passwordNoImg} alt="img" />
            </div>
          }
        />
      </div>
      <div className="mt-3">
        <Input
          type={toggle.check_password ? 'text' : 'password'}
          placeholder="请再次输入密码"
          maxLength={18}
          value={formData.check_password}
          onChange={(e: any) => onChange(e.target.value, 'check_password')}
          rightChild={
            <div
              className="cursor-pointer flex items-center"
              onClick={() => setToggle({ ...toggle, check_password: !toggle.check_password })}
            >
              <img src={toggle.check_password ? passwordVisibleImg : passwordNoImg} alt="img" />
            </div>
          }
        />
      </div>
    </div>
  )
})

export default memo(RetrievePassword)
