import React, { memo } from 'react'

const MoreIcon = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
      <path
        d="M9.6 18.1C10.4837 18.1 11.2 17.3837 11.2 16.5C11.2 15.6163 10.4837 14.9 9.6 14.9C8.71634 14.9 8 15.6163 8 16.5C8 17.3837 8.71634 18.1 9.6 18.1Z"
        fill="#8E9DBA"
      />
      <path
        d="M15.9999 18.1C16.8836 18.1 17.5999 17.3837 17.5999 16.5C17.5999 15.6163 16.8836 14.9 15.9999 14.9C15.1162 14.9 14.3999 15.6163 14.3999 16.5C14.3999 17.3837 15.1162 18.1 15.9999 18.1Z"
        fill="#8E9DBA"
      />
      <path
        d="M22.4 18.1C23.2837 18.1 24 17.3837 24 16.5C24 15.6163 23.2837 14.9 22.4 14.9C21.5164 14.9 20.8 15.6163 20.8 16.5C20.8 17.3837 21.5164 18.1 22.4 18.1Z"
        fill="#8E9DBA"
      />
    </svg>
  )
})

export default MoreIcon
