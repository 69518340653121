import React, { memo } from 'react'

const EmptyIcon = memo(() => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="210"
      height="164"
      viewBox="0 0 210 164"
      fill="none"
    >
      <g filter="url(#filter0_f_1926_3073)">
        <ellipse
          cx="102.66"
          cy="132.501"
          rx="78.9985"
          ry="15.1426"
          fill="#1F88F8"
          fillOpacity="0.35"
        />
      </g>
      <path
        d="M103.528 163.043C148.773 163.043 185.451 126.545 185.451 81.5216C185.451 36.4985 148.773 0 103.528 0C58.2835 0 21.6055 36.4985 21.6055 81.5216C21.6055 126.545 58.2835 163.043 103.528 163.043Z"
        fill="url(#paint0_linear_1926_3073)"
      />
      <g filter="url(#filter1_d_1926_3073)">
        <path
          d="M172.152 55.1699L101.95 103.922L31.7476 55.1699L96.012 10.5227C97.7548 9.31396 99.8281 8.66592 101.952 8.66592C104.077 8.66592 106.15 9.31396 107.893 10.5227L120.712 19.4405L124.068 21.7599L157.864 45.2431L161.424 47.7306L172.152 55.1699Z"
          fill="url(#paint1_linear_1926_3073)"
        />
      </g>
      <path
        d="M90.5347 95.9958L31.7476 137.593V55.1697L90.5347 95.9958Z"
        fill="url(#paint2_linear_1926_3073)"
      />
      <g filter="url(#filter2_f_1926_3073)">
        <ellipse
          cx="136.249"
          cy="53.3886"
          rx="26.1255"
          ry="8.3439"
          fill="#1594FF"
          fillOpacity="0.3"
        />
      </g>
      <path
        d="M113.37 95.9958L172.157 137.593V55.1697L113.37 95.9958Z"
        fill="url(#paint3_linear_1926_3073)"
      />
      <path
        d="M31.7476 137.593L95.9362 92.1807C97.693 90.9359 99.7959 90.2669 101.952 90.2669C104.109 90.2669 106.212 90.9359 107.969 92.1807L172.152 137.593H31.7476Z"
        fill="url(#paint4_linear_1926_3073)"
      />
      <g filter="url(#filter3_d_1926_3073)">
        <path
          d="M39.1651 40.2345C28.4027 45.2442 16.8376 48.3281 4.99989 49.3448C6.59941 53.0448 17.9644 71.5656 51.7016 86.8263C52.6136 86.9659 73.681 77.2133 85.2424 70.5254C84.8496 71.0839 49.3304 58.0781 39.1651 40.2345Z"
          fill="url(#paint5_linear_1926_3073)"
        />
      </g>
      <path
        d="M137.026 60.3746C149.946 60.3746 160.42 49.9677 160.42 37.1301C160.42 24.2925 149.946 13.8856 137.026 13.8856C124.106 13.8856 113.633 24.2925 113.633 37.1301C113.633 49.9677 124.106 60.3746 137.026 60.3746Z"
        fill="url(#paint6_linear_1926_3073)"
      />
      <path
        d="M136.858 50.6851C139.197 50.6851 140.831 49.0988 140.831 46.8853C140.831 44.6719 139.197 43.0856 136.858 43.0856C134.556 43.0856 132.886 44.6719 132.886 46.8853C132.886 49.0988 134.556 50.6851 136.858 50.6851ZM140.051 41.7206C140.051 39.1383 141.314 38.1422 143.244 37.1462C145.843 35.8181 147.774 33.6416 147.774 30.5427C147.774 25.9314 143.356 22.685 137.452 22.685C131.215 22.685 126.759 26.3372 126.314 31.465L133.48 32.7562C133.739 30.3952 135.15 28.6613 137.155 28.6613C138.752 28.6613 139.977 29.768 139.977 31.2806C139.977 32.7931 138.603 33.6416 137.415 34.4163C134.816 36.1132 133.702 38.0316 133.702 41.7206H140.051Z"
        fill="white"
      />
      <path
        d="M39.9956 49.2928C39.5019 48.9525 38.8782 48.8603 38.3084 49.0435L19.4681 55.1C18.7961 55.316 18.5585 56.1422 19.0126 56.6837C19.3775 57.1187 19.9726 57.2853 20.5094 57.1026L39.8267 50.5283C40.3702 50.3433 40.4692 49.6193 39.9956 49.2928Z"
        fill="#C9DFFF"
      />
      <path
        d="M39.67 56.7482C39.1933 56.3035 38.5113 56.155 37.894 56.3614L24.8484 60.7238C24.2758 60.9153 24.0751 61.6205 24.4608 62.086C24.7752 62.4655 25.2915 62.6121 25.7576 62.4544L39.4393 57.8257C39.8997 57.67 40.0259 57.0803 39.67 56.7482Z"
        fill="#C9DFFF"
      />
      <path
        d="M46.6659 60.4397C46.1687 60.0441 45.5018 59.9342 44.9052 60.1496L29.8804 65.5756C29.2932 65.7876 29.1181 66.5311 29.5487 66.9845C29.8772 67.3305 30.3802 67.449 30.8278 67.2857L46.4828 61.5776C46.968 61.4006 47.0708 60.7619 46.6659 60.4397Z"
        fill="#C9DFFF"
      />
      <path
        d="M51.9165 71.6328C51.4554 71.1264 50.7309 70.9527 50.0914 71.1951L41.5008 74.4525C40.9758 74.6515 40.7878 75.2956 41.123 75.7467C41.4367 76.1687 41.9939 76.3305 42.4842 76.1419L51.6887 72.5997C52.0842 72.4475 52.2022 71.9466 51.9165 71.6328Z"
        fill="#C9DFFF"
      />
      <g filter="url(#filter4_d_1926_3073)">
        <path
          d="M163.573 66.063C176.623 72.1377 190.646 75.8771 205 77.1099C203.061 81.5963 189.28 104.054 148.371 122.558C147.266 122.728 121.72 110.902 107.701 102.793C108.178 103.47 151.247 87.6996 163.573 66.063Z"
          fill="url(#paint7_linear_1926_3073)"
        />
      </g>
      <path
        d="M162.567 77.0373C163.165 76.6246 163.922 76.5129 164.613 76.735L187.457 84.0789C188.272 84.3408 188.56 85.3426 188.01 85.9992C187.567 86.5266 186.846 86.7286 186.195 86.5071L162.771 78.5354C162.112 78.3111 161.992 77.4332 162.567 77.0373Z"
        fill="#C9DFFF"
      />
      <path
        d="M156.172 83.0793C156.75 82.5401 157.577 82.3599 158.326 82.6102L174.144 87.8999C174.839 88.1321 175.082 88.9872 174.614 89.5517C174.233 90.0118 173.607 90.1897 173.042 89.9984L156.452 84.3858C155.894 84.197 155.741 83.482 156.172 83.0793Z"
        fill="#C9DFFF"
      />
      <path
        d="M149.603 88.0946C150.206 87.6149 151.015 87.4816 151.738 87.7429L169.957 94.3221C170.669 94.5793 170.881 95.4807 170.359 96.0306C169.96 96.4501 169.351 96.5937 168.808 96.3958L149.825 89.4743C149.237 89.2598 149.112 88.4853 149.603 88.0946Z"
        fill="#C9DFFF"
      />
      <path
        d="M134.819 98.1301C135.378 97.516 136.256 97.3054 137.032 97.5994L147.448 101.549C148.085 101.79 148.313 102.571 147.906 103.118C147.526 103.63 146.85 103.826 146.256 103.598L135.095 99.3025C134.615 99.118 134.472 98.5106 134.819 98.1301Z"
        fill="#C9DFFF"
      />
      <path
        d="M141.613 92.7705C142.227 92.3878 142.983 92.3082 143.661 92.5548L167.648 101.269C168.411 101.546 168.594 102.54 167.979 103.074C167.574 103.425 167.009 103.53 166.506 103.345L141.767 94.2905C141.102 94.0469 141.011 93.146 141.613 92.7705Z"
        fill="#C9DFFF"
      />
      <path
        d="M57.108 62.8863C56.6017 62.5707 55.9785 62.5051 55.4188 62.7084L35.6374 69.8949C35.0076 70.1238 34.857 70.9433 35.3639 71.3833C35.6982 71.6734 36.1638 71.7593 36.5788 71.6075L56.9807 64.1398C57.5295 63.9389 57.6049 63.196 57.108 62.8863Z"
        fill="#C9DFFF"
      />
      <path
        d="M171.759 19.0859C171.759 21.1578 170.067 22.8436 167.973 22.8436C165.879 22.8436 164.188 21.1578 164.188 19.0859C164.188 17.0141 165.879 15.3283 167.973 15.3283C170.067 15.3283 171.759 17.0141 171.759 19.0859Z"
        stroke="url(#paint8_linear_1926_3073)"
        strokeWidth="1.1377"
      />
      <path
        d="M50.4619 145.172C50.4619 147.243 48.7706 148.929 46.6765 148.929C44.5825 148.929 42.8911 147.243 42.8911 145.172C42.8911 143.1 44.5825 141.414 46.6765 141.414C48.7706 141.414 50.4619 143.1 50.4619 145.172Z"
        stroke="url(#paint9_linear_1926_3073)"
        strokeWidth="1.1377"
      />
      <path
        d="M155.586 151.97C155.586 154.042 153.895 155.728 151.801 155.728C149.707 155.728 148.015 154.042 148.015 151.97C148.015 149.898 149.707 148.213 151.801 148.213C153.895 148.213 155.586 149.898 155.586 151.97Z"
        stroke="url(#paint10_linear_1926_3073)"
        strokeWidth="1.1377"
      />
      <path
        d="M58.8545 20.2539C59.0797 19.3269 60.3981 19.3269 60.6233 20.2539L62.5106 28.0222C62.5912 28.3539 62.8507 28.6127 63.1827 28.6923L70.9778 30.562C71.9083 30.7852 71.9083 32.109 70.9778 32.3321L63.1827 34.2018C62.8507 34.2815 62.5912 34.5402 62.5106 34.872L60.6233 42.6402C60.3981 43.5673 59.0797 43.5673 58.8545 42.6402L56.9672 34.872C56.8866 34.5402 56.6271 34.2815 56.2951 34.2018L48.5 32.3321C47.5695 32.109 47.5695 30.7852 48.5 30.562L56.2951 28.6923C56.6271 28.6127 56.8866 28.3539 56.9672 28.0222L58.8545 20.2539Z"
        fill="url(#paint11_linear_1926_3073)"
      />
      <path
        d="M183.494 127.759C183.72 126.832 185.038 126.832 185.263 127.759L187.15 135.527C187.231 135.859 187.491 136.117 187.823 136.197L195.618 138.067C196.548 138.29 196.548 139.614 195.618 139.837L187.823 141.706C187.491 141.786 187.231 142.045 187.15 142.377L185.263 150.145C185.038 151.072 183.72 151.072 183.494 150.145L181.607 142.377C181.527 142.045 181.267 141.786 180.935 141.706L173.14 139.837C172.209 139.614 172.209 138.29 173.14 138.067L180.935 136.197C181.267 136.117 181.527 135.859 181.607 135.527L183.494 127.759Z"
        fill="url(#paint12_linear_1926_3073)"
      />
      <path
        d="M24.7399 118.036C25.3185 117.944 25.7809 118.509 25.5719 119.053L22.6684 126.607C22.4596 127.15 21.7371 127.265 21.3676 126.813L16.2352 120.538C15.8657 120.086 16.1261 119.406 16.7041 119.314L24.7399 118.036Z"
        fill="url(#paint13_linear_1926_3073)"
      />
      <path
        d="M185.191 56.193C185.046 55.629 185.57 55.121 186.134 55.2784L193.969 57.4661C194.533 57.6235 194.714 58.3285 194.295 58.735L188.471 64.3829C188.051 64.7894 187.347 64.5925 187.202 64.0285L185.191 56.193Z"
        fill="url(#paint14_linear_1926_3073)"
      />
      <defs>
        <filter
          id="filter0_f_1926_3073"
          x="14.1806"
          y="107.877"
          width="176.958"
          height="49.2469"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.7404" result="effect1_foregroundBlur_1926_3073" />
        </filter>
        <filter
          id="filter1_d_1926_3073"
          x="30.2306"
          y="8.66592"
          width="143.438"
          height="98.2902"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.51693" />
          <feGaussianBlur stdDeviation="0.758464" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1926_3073" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1926_3073"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_f_1926_3073"
          x="100.643"
          y="35.5639"
          width="71.2126"
          height="35.6494"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.7404" result="effect1_foregroundBlur_1926_3073" />
        </filter>
        <filter
          id="filter3_d_1926_3073"
          x="0.449214"
          y="37.2006"
          width="89.344"
          height="55.6949"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.51693" />
          <feGaussianBlur stdDeviation="2.27539" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0196078 0 0 0 0 0.168627 0 0 0 0 0.396078 0 0 0 0.15 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1926_3073" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1926_3073"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_1926_3073"
          x="98.955"
          y="65.3046"
          width="110.193"
          height="69.391"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2.29921" dy="5.68848" />
          <feGaussianBlur stdDeviation="3.22347" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.021441 0 0 0 0 0.168056 0 0 0 0 0.395833 0 0 0 0.15 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1926_3073" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1926_3073"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1926_3073"
          x1="102.897"
          y1="-26.5454"
          x2="104.558"
          y2="259.746"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CAE0FF" stopOpacity="0" />
          <stop offset="1" stopColor="#BCD8FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1926_3073"
          x1="101.95"
          y1="8.66592"
          x2="101.95"
          y2="103.922"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF4FF" />
          <stop offset="1" stopColor="#ABCCFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1926_3073"
          x1="61.1411"
          y1="55.1697"
          x2="61.1411"
          y2="137.593"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABCCFF" />
          <stop offset="1" stopColor="#70A8FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1926_3073"
          x1="142.764"
          y1="55.1697"
          x2="142.764"
          y2="137.593"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABCCFF" />
          <stop offset="1" stopColor="#70A8FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1926_3073"
          x1="101.95"
          y1="90.2669"
          x2="101.95"
          y2="137.593"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70A8FF" />
          <stop offset="1" stopColor="#5597FD" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1926_3073"
          x1="13.7084"
          y1="60.9706"
          x2="66.2513"
          y2="50.6603"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6DDFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1926_3073"
          x1="137.026"
          y1="13.8856"
          x2="137.026"
          y2="60.3746"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1DB0FF" />
          <stop offset="1" stopColor="#148EFF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1926_3073"
          x1="194.44"
          y1="91.2069"
          x2="130.729"
          y2="78.705"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6DDFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1926_3073"
          x1="162.621"
          y1="16.3819"
          x2="176.154"
          y2="28.1851"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94BFFF" />
          <stop offset="1" stopColor="#4C94FE" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1926_3073"
          x1="41.3244"
          y1="142.468"
          x2="54.8567"
          y2="154.271"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94BFFF" />
          <stop offset="1" stopColor="#4C94FE" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1926_3073"
          x1="146.449"
          y1="149.266"
          x2="159.981"
          y2="161.069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94BFFF" />
          <stop offset="1" stopColor="#4C94FE" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1926_3073"
          x1="59.7389"
          y1="16.6135"
          x2="59.7389"
          y2="46.2807"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70A8FF" />
          <stop offset="1" stopColor="#5597FD" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1926_3073"
          x1="184.379"
          y1="124.118"
          x2="184.379"
          y2="153.785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70A8FF" />
          <stop offset="1" stopColor="#5597FD" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1926_3073"
          x1="26.0425"
          y1="117.829"
          x2="16.4369"
          y2="125.684"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70A8FF" />
          <stop offset="1" stopColor="#5597FD" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1926_3073"
          x1="184.866"
          y1="54.9242"
          x2="193.498"
          y2="63.8258"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70A8FF" />
          <stop offset="1" stopColor="#5597FD" />
        </linearGradient>
      </defs>
    </svg>
  )
})

export default EmptyIcon
