import React, { memo, useEffect, useState, useLayoutEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import CSS from './index.module.scss'
import Header from '@/components/Header'
import InputContainer from './cpns/InputContainer'
import ListContainer from './cpns/ListContainer'

const ChatTool = memo(() => {
  const location = useLocation()
  const [listContainerRef, setListContainerRef] = useState<HTMLDivElement | null>(null)

  const assistantItem = location.state?.assistantItem
  // console.log('assistantItem: ', assistantItem)

  const headerCenter = (
    <div className="header_title_text">
      {assistantItem?.title ? assistantItem?.title : 'ChatAIQ'}
    </div>
  )
  return (
    <div className={CSS.wrapper}>
      <Header center={headerCenter} />
      <div className={classNames([CSS.content])}>
        <ListContainer
          listContainerRef={listContainerRef}
          setListContainerRef={setListContainerRef}
        />
        <InputContainer listContainerRef={listContainerRef} />
      </div>
    </div>
  )
})

export default ChatTool
