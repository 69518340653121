import React, { memo } from 'react'

const Copy1 = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <path
        d="M14.4752 4.5H6.67053C5.56381 4.5 4.66663 5.39718 4.66663 6.50391V14.3086C4.66663 15.4153 5.56381 16.3125 6.67053 16.3125H14.4752C15.5819 16.3125 16.4791 15.4153 16.4791 14.3086V6.50391C16.4791 5.39718 15.5819 4.5 14.4752 4.5Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
      <path
        d="M13.649 4.5L13.6666 3.65625C13.6651 3.13456 13.4572 2.63466 13.0884 2.26577C12.7195 1.89688 12.2196 1.68898 11.6979 1.6875H4.10413C3.50793 1.68926 2.93666 1.92688 2.51508 2.34846C2.09351 2.77003 1.85589 3.3413 1.85413 3.9375V11.5312C1.85561 12.0529 2.06351 12.5528 2.4324 12.9217C2.80129 13.2906 3.30119 13.4985 3.82288 13.5H4.66663"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})

export default Copy1
