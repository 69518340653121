import React, { memo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import CSS from './right.module.scss'
import GlobalPopUp from '@/components/GlobalPopUp'

const Right = memo(() => {
  return (
    <div className={CSS.wrapper}>
      <Outlet />
      <GlobalPopUp />
    </div>
  )
})

export default Right
