import { create } from 'zustand'
import { message } from 'antd'
import * as creationAPI from '@/api/creation'
import { persist } from 'zustand/middleware'

function createDatas() {
  return {
    // 例子列表
    exampleList: [],
    // 用户输入
    input: '',
    // 是否正在工作
    isInput: false,
    // ai回复
    assistant: '',
    // 是否自动滚动
    isScroll: true,
    // 是否收藏 没有功能的只是暂时使用
    isCollect: false,
  }
}

const store = (set: any, get: any) => ({
  tabIndex: 0,
  writing: createDatas(),
  copywriting: createDatas(),
  complemented: createDatas(),
  changeIsCollect(key: string, flag?: boolean) {
    const oldData = get()[key]
    set({
      [key]: {
        ...oldData,
        isCollect: flag !== void 0 ? flag : !oldData.isCollect,
      },
    })
  },
  changeIsInput(key: string, flag: boolean) {
    const oldData = get()[key]
    set({
      [key]: {
        ...oldData,
        isInput: flag,
      },
    })
  },
  changeAssistant(key: string, content: string) {
    const oldData = get()[key]
    set({
      [key]: {
        ...oldData,
        assistant: content,
      },
    })
  },
  changeInput(key: any, title: any) {
    set({
      [key]: {
        ...get()[key],
        input: title,
      },
    })
  },
  changetabIndex(tabIndex: number) {
    set({
      tabIndex,
    })
  },
  async fetchCreateExampleList(item: any, index: number) {
    try {
      if (get()[item.key].exampleList.length > 0) {
        set({
          tabIndex: index,
        })
        return
      }
      const res: any = await creationAPI.getCreateExampleList(item.id)
      // console.log('fetchCreateExampleList res: ', res)
      if (res.code !== 1) {
        message.error(res.msg)
        return
      }
      set({
        [item.key]: {
          exampleList: res.data,
        },
        tabIndex: index,
      })
    } catch (error) {
      console.log('error: ', error)
    }
  },
})

function partialize(state: any) {
  // 过滤字段
  const filterArr = ['isInput']
  return Object.fromEntries(Object.entries(state).filter(([key]) => !filterArr.includes(key)))
}

const useCreationStore = create(persist(store, { name: 'creation_state', partialize }))

export default useCreationStore
