import React, { memo } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import Input from '@/ui/Input'
import CSS from './index.module.scss'
import { useCaptcha } from '@/hooks/useCaptcha'
import { useRequest } from '@/hooks/useRequest'
import * as loginAPI from '@/api/login'
import antdUI from '@/antd-ui'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const CodeLogin = memo((props: any = {}) => {
  const { loginFormData } = props
  const { mobile } = loginFormData
  const loginCaptcha = useCaptcha()
  const captchaReq = useRequest(loginAPI.getMobileCaptcha)

  const getCaptcha = () => {
    if (!/^1(3|4|5|6|7|8|9)\d{9}$/g.test(mobile)) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请输入正确的手机号',
      })
      return
    }
    captchaReq.run(
      { mobile, event: 'mobilelogin' },
      {
        onSuccess(res) {
          console.log('res: ', res)
          loginCaptcha.getCode()
        },
      },
      {
        code: 1,
      }
    )
  }

  return (
    <div>
      <div className="mt-7">
        <Input
          type="number"
          placeholder="请输入手机号"
          maxLength={11}
          value={props.loginFormData.mobile}
          onChange={(e: any) => props.onChange?.(e.target.value, 'mobile')}
        />
      </div>
      <div className="mt-3">
        <Input
          type="number"
          placeholder="请输入验证码"
          maxLength={6}
          value={props.loginFormData.captcha}
          onChange={(e: any) => props.onChange?.(e.target.value, 'captcha')}
          rightChild={
            captchaReq.loading ? (
              <Spin indicator={antIcon} />
            ) : loginCaptcha.isSetup ? (
              <div className={CSS.code_box}>{loginCaptcha.code}s</div>
            ) : (
              <div className={CSS.code_box} onClick={getCaptcha}>
                {loginCaptcha.isInit ? '重新获取' : '获取验证码'}
              </div>
            )
          }
        />
      </div>
    </div>
  )
})

export default CodeLogin
