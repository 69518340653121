import React, { RefObject, useRef } from 'react'

import ReactMarkdown from 'react-markdown'
import 'katex/dist/katex.min.css'
import RemarkMath from 'remark-math'
import RemarkBreaks from 'remark-breaks'
import RehypeKatex from 'rehype-katex'
import RemarkGfm from 'remark-gfm'
import RehypeHighlight from 'rehype-highlight'

function _MarkDownContent(props: { content: string }) {
  return (
    <ReactMarkdown
      remarkPlugins={[RemarkMath, RemarkGfm, RemarkBreaks]}
      rehypePlugins={[
        RehypeKatex,
        [
          RehypeHighlight,
          {
            detect: false,
            ignoreMissing: true,
          },
        ],
      ]}
      components={{
        // pre: PreCode,
        a: aProps => {
          const href = aProps.href || ''
          const isInternal = /^\/#/i.test(href)
          const target = isInternal ? '_self' : aProps.target ?? '_blank'
          return <a {...aProps} target={target} />
        },
      }}
    >
      {props.content}
    </ReactMarkdown>
  )
}

export const MarkdownContent = React.memo(_MarkDownContent)

export function Markdown(
  props: {
    content: string
    loading?: boolean
    fontSize?: number
    parentRef?: RefObject<HTMLDivElement>
    defaultShow?: boolean
  } & React.DOMAttributes<HTMLDivElement>
) {
  const mdRef = useRef<HTMLDivElement>(null)
  const renderedHeight = useRef(0)
  const inView = useRef(!!props.defaultShow)

  const parent = props.parentRef?.current
  const md = mdRef.current

  const checkInView = () => {
    if (parent && md) {
      const parentBounds = parent.getBoundingClientRect()
      const twoScreenHeight = Math.max(500, parentBounds.height * 2)
      const mdBounds = md.getBoundingClientRect()
      const parentTop = parentBounds.top - twoScreenHeight
      const parentBottom = parentBounds.bottom + twoScreenHeight
      const isOverlap = Math.max(parentTop, mdBounds.top) <= Math.min(parentBottom, mdBounds.bottom)
      inView.current = isOverlap
    }

    if (inView.current && md) {
      renderedHeight.current = Math.max(renderedHeight.current, md.getBoundingClientRect().height)
    }
  }

  setTimeout(() => checkInView(), 1)

  return (
    <div
      className="markdown-body"
      style={{
        // fontSize: `${props.fontSize ?? 14}px`,
        height: !inView.current && renderedHeight.current > 0 ? renderedHeight.current : 'auto',
      }}
      ref={mdRef}
    >
      <MarkdownContent content={props.content} />
    </div>
  )
}
