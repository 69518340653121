import React, { memo, useEffect, Fragment } from 'react'
import CSS from './index.module.scss'
import classNames from 'classnames'
import MsgItem from '../MsgItem'
import useChatStore from '@/store/chat'
import { smoothScrollTo } from '@/utils'
import { Divider } from 'antd'
const listContainer = memo((props: any) => {
  const chatList = useChatStore(state => state.list)
  useEffect(() => {
    if (props.listContainerRef) {
      props.listContainerRef._prevScrollTop = 0
      smoothScrollTo(props.listContainerRef, props.listContainerRef.scrollHeight + 9999)
    }
  }, [props.listContainerRef])
  // 监听滚动事件
  const scrollHandle = (e: any) => {
    const { scrollTop, scrollHeight, offsetHeight } = e.target
    if (scrollTop < scrollHeight - offsetHeight) {
      e.target._isScroll = false
    } else {
      e.target._isScroll = true
    }
    e.target._prevScrollTop = scrollTop
  }
  return (
    <div
      className={classNames([CSS.wrapper, 'scrollbar_style'])}
      ref={el => props.setListContainerRef(el)}
      onScroll={scrollHandle}
    >
      <div className={CSS.list_content}>
        {chatList.map(v => {
          // <Divider key={v.id}>对话重置</Divider>
          return (
            <Fragment key={v.id}>
              {(v as any).isResetSession ? (
                <Divider key={v.id} className={CSS.divider}>
                  对话重置
                </Divider>
              ) : (
                <MsgItem key={v.id} item={v} />
              )}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
})

export default listContainer
