import React, { memo } from 'react'

const Reload2 = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M3.9834 13.6833L6.85007 9.47501H4.8584C4.97817 8.09353 5.56006 6.79274 6.51011 5.78267C7.46016 4.7726 8.72291 4.11221 10.0945 3.90814C11.466 3.70407 12.8663 3.96822 14.0693 4.65794C15.2723 5.34766 16.2077 6.4227 16.7244 7.70946C17.2412 8.99623 17.3092 10.4196 16.9175 11.7498C16.5257 13.08 15.6971 14.2393 14.5654 15.0406C13.4337 15.8418 12.0649 16.2383 10.6802 16.1659C9.29539 16.0935 7.97542 15.5566 6.9334 14.6417L5.8834 16C7.23318 17.1555 8.93342 17.8217 10.7089 17.8908C12.4844 17.9598 14.2313 17.4277 15.6667 16.3805C17.1021 15.3333 18.1422 13.8323 18.6184 12.1204C19.0947 10.4086 18.9794 8.58618 18.2911 6.94807C17.6028 5.30996 16.3818 3.95207 14.8258 3.09418C13.2698 2.23629 11.4698 1.92862 9.71717 2.22096C7.96455 2.5133 6.36186 3.38855 5.16854 4.70503C3.97521 6.02151 3.26108 7.70218 3.14173 9.47501H1.1084L3.9834 13.6833Z"
        fill="#4A5D82"
      />
    </svg>
  )
})

export default Reload2
