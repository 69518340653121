import React, { memo } from 'react'

const CollectYes = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_282_1231)">
        <path
          d="M17.1388 5.85185L11.9218 5.09366L9.58972 0.365748C9.52602 0.2363 9.42123 0.13151 9.29178 0.0678133C8.96714 -0.0924547 8.57263 0.041102 8.41031 0.365748L6.0782 5.09366L0.861271 5.85185C0.717441 5.87239 0.585939 5.9402 0.485258 6.04293C0.36354 6.16804 0.296467 6.33635 0.298779 6.51088C0.301091 6.68541 0.372597 6.85189 0.497586 6.97372L4.2721 10.6537L3.38036 15.8501C3.35944 15.971 3.37282 16.0953 3.41897 16.209C3.46512 16.3226 3.54219 16.4211 3.64145 16.4932C3.7407 16.5653 3.85817 16.6081 3.98054 16.6168C4.1029 16.6256 4.22526 16.5998 4.33375 16.5425L9.00001 14.0892L13.6663 16.5425C13.7937 16.6104 13.9416 16.633 14.0834 16.6083C14.4409 16.5467 14.6813 16.2076 14.6197 15.8501L13.7279 10.6537L17.5024 6.97372C17.6052 6.87304 17.673 6.74154 17.6935 6.59771C17.749 6.23813 17.4983 5.90527 17.1388 5.85185Z"
          fill="#FFA53B"
        />
      </g>
      <defs>
        <clipPath id="clip0_282_1231">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
})

export default CollectYes
