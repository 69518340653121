import React, { memo } from 'react'

const CollectNo = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <g clipPath="url(#clip0_106_555)">
        <path
          d="M17.9721 5.85185L12.7552 5.09366L10.4231 0.365748C10.3594 0.2363 10.2546 0.13151 10.1251 0.0678133C9.80048 -0.0924547 9.40597 0.041102 9.24365 0.365748L6.91154 5.09366L1.69461 5.85185C1.55078 5.87239 1.41928 5.9402 1.3186 6.04293C1.19688 6.16804 1.12981 6.33635 1.13212 6.51088C1.13443 6.68541 1.20594 6.85189 1.33093 6.97372L5.10545 10.6537L4.2137 15.8501C4.19279 15.971 4.20616 16.0953 4.25231 16.209C4.29846 16.3226 4.37553 16.4211 4.47479 16.4932C4.57405 16.5653 4.69152 16.6081 4.81388 16.6168C4.93625 16.6256 5.05861 16.5998 5.16709 16.5425L9.83335 14.0892L14.4996 16.5425C14.627 16.6104 14.775 16.633 14.9167 16.6083C15.2743 16.5467 15.5147 16.2076 15.453 15.8501L14.5613 10.6537L18.3358 6.97372C18.4385 6.87304 18.5063 6.74154 18.5269 6.59771C18.5823 6.23813 18.3317 5.90527 17.9721 5.85185ZM12.973 10.1359L13.7147 14.457L9.83335 12.4187L5.95199 14.4591L6.69374 10.138L3.55413 7.07646L7.8937 6.44566L9.83335 2.51498L11.773 6.44566L16.1126 7.07646L12.973 10.1359Z"
          fill="#4A5D82"
        />
      </g>
      <defs>
        <clipPath id="clip0_106_555">
          <rect width="18" height="18" fill="white" transform="translate(0.833344)" />
        </clipPath>
      </defs>
    </svg>
  )
})

export default CollectNo
