import moment from 'moment'

/**
 * @param isLove 0(默认) 1(喜欢) 2(不喜欢)
 */
export function createChatItem(data?: any) {
  const {
    user = '',
    assistant = '',
    isTool = true,
    isLove = 0,
    done = false,
    unique_id,
    created_at = moment().format('YYYY-MM-DD HH:mm:ss'),
  } = data

  return {
    chat: {
      user,
      assistant,
    },
    created_at,
    id: Date.now(),
    isTool,
    isLove,
    done,
    unique_id,
  }
}
