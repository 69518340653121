import React, { ChangeEvent, memo } from 'react'
import classNames from 'classnames'
import CSS from './index.module.scss'
const Input = memo((props: any = {}) => {
  const {
    value = '',
    placeholder = '请输入内容',
    type = 'text',
    maxLength = -1,
    onChange,
    rightChild,
  } = props
  const change = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (maxLength !== -1 && value.length > maxLength) {
      return
    }

    if (type === 'number') {
      if (!/^\d+$/.test(value) && value !== '') {
        return
      }
    }

    onChange?.(e)
  }
  return (
    <div className={classNames([CSS.input_wrapper, 'flex', 'items-center'])}>
      <input
        type={type}
        className={classNames([CSS.input])}
        onChange={change}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
      />
      {rightChild && <div className={classNames(['ml-2'])}>{rightChild}</div>}
    </div>
  )
})

export default Input
