import React, { memo, useEffect, useMemo, Fragment, useRef } from 'react'
import CSS from './index.module.scss'
import Header from '@/components/Header'
import useCreationStore from '@/store/creation'
import classNames from 'classnames'
import { Button, message, notification } from 'antd'
import CollectNo from '@/assets/svg/CollectNo'
import Reload1 from '@/assets/svg/Reload1'
import Copy1 from '@/assets/svg/Copy1'
import { Markdown } from '@/components/markdown'
import { fetchPostStream2, getSessionId } from '@/api/chat'
import analysisStreamApi from '@/utils/analysisStream'
import TimeIonc1 from '@/assets/svg/TimeIonc1'
import CollectYes from '@/assets/svg/CollectYes'
import useIndexStore from '@/store'
import { smoothScrollTo } from '@/utils'
import useUserStore from '@/store/user'
import { copyTool, notificationTool } from '@/utils/tool'
import PenIcon from '@/assets/svg/PenIcon'
import Tbas from '@/components/Tabs'
import { useRequest } from '@/hooks/useRequest'
import useStreamTypewriting from '@/hooks/useStreamTypewriting'

const tabsList = [
  {
    text: '文字写作',
    key: 'writing',
    id: '1',
  },
  {
    text: '文案优化',
    key: 'copywriting',
    id: '2',
  },
  {
    text: '内容补全',
    key: 'complemented',
    id: '3',
  },
]

// 保存dom
const scrollRefs: any = {}

const Creation = memo(() => {
  const {
    tabIndex,
    changeInput,
    fetchCreateExampleList,
    changeAssistant,
    changeIsInput,
    changeIsCollect,
  } = useCreationStore()
  const creationStore: any = useCreationStore()

  const changeLoginSwitch = useIndexStore(state => state.changeLoginSwitch)
  const token = useIndexStore(state => state.token)
  const fetchUserInfo = useUserStore(state => state.fetchUserInfo)
  const getSessionIdReq = useRequest(getSessionId)
  const streamTypewritingTool = useStreamTypewriting()
  const sessionId = useRef(null)

  useEffect(() => {
    fetchCreateExampleList(tabsList[tabIndex], tabIndex)
    const dom = scrollRefs[tabsList[tabIndex].key]
    if (dom) {
      dom._prevScrollTop = 0
      smoothScrollTo(dom, dom.scrollHeight + 9999)
    }
  }, [])

  const getSessionIdHandle = async () => {
    if (!token) return
    const [err, res] = await getSessionIdReq.run({ scene_unique_id: 0 })
    if (err) return
    sessionId.current = res?.data?.session_id
    return sessionId.current
  }

  useEffect(() => {
    if (token) {
      getSessionIdHandle()
    }
  }, [token])

  const tabItemHandle = (item: any, index: number) => {
    fetchCreateExampleList(item, index)
  }

  const exampleItemHandle = (tabItem: any, item: any) => {
    changeInput(tabItem.key, item.title)
  }

  const inputHandle = (e: React.ChangeEvent<HTMLTextAreaElement>, tabItem: any) => {
    changeInput(tabItem.key, e.target.value)
  }

  const copyHandle = (text: any) => {
    /* 复制内容到文本域 */
    copyTool(text)
  }

  const scrollToBottom = (key: string) => {
    const scrollDom = scrollRefs[key]
    // 是否滚动
    if (scrollDom?._isScroll) {
      smoothScrollTo(scrollDom, scrollDom.scrollHeight + 9999)
    }
  }

  const submitPostStream = async (tabItem: any) => {
    if (!token) {
      message.warning('请先登录~')
      changeLoginSwitch(true)
      return
    }
    const prompt = creationStore[tabItem.key].input ?? ''
    console.log('prompt: ', prompt)
    if (prompt.trim() === '') {
      notificationTool('请输入你的问题再提交~', 'error')
      return
    }

    if (!sessionId.current) {
      await getSessionIdHandle()
    }

    changeAssistant(tabItem.key, '')
    changeIsInput(tabItem.key, true)
    changeIsCollect(tabItem.key, false)
    const dom = scrollRefs[tabItem.key]
    dom._isScroll = true
    dom._prevScrollTop = 0

    // 开始打字
    streamTypewritingTool.typewriting()

    // 监听打字
    streamTypewritingTool.config.streamCallback = (text: string) => {
      const state: any = useCreationStore.getState()
      changeAssistant(tabItem.key, state[tabItem.key].assistant + text)
      // 页面滚动
      scrollToBottom(tabItem.key)
    }
    // 监听结束
    streamTypewritingTool.config.endCallback = () => {
      // 打完字解放禁止操作状态
      changeIsInput(tabItem.key, false)
    }

    try {
      const resp = await fetchPostStream2({
        data: {
          context: [],
          question: prompt,
          session_id: sessionId.current,
        },
        onDownloadProgress: (e: any) => {
          analysisStreamApi.analysisData2(e, (data: any) => {
            // console.log('data: ', data)
            // 判断是否结束
            if (data === '[DONE]') {
              // changeIsInput(tabItem.key, false)
              return
            }

            const msg = data?.choices[0]?.delta?.content
            if (msg !== void 0) {
              // setCS(state => state + msg)
              // const state: any = useCreationStore.getState()
              // changeAssistant(tabItem.key, state[tabItem.key].assistant + msg)
              // // 是否滚动 解决闭包问题，必须在这里重新获取
              // scrollToBottom(tabItem.key)
              streamTypewritingTool.config.tempChatAssistant += msg
            }
          })
        },
      })
      // if (resp.status === 500) {
      //   changeIsInput(tabItem.key, false)
      // }
    } catch (error: any) {
      console.log('error: ', error)
      // changeIsInput(tabItem.key, false)
      changeAssistant(tabItem.key, '很抱歉，网络连接超时，请检查您的网络设置后再试。')
    } finally {
      fetchUserInfo({}, false)
    }
    // scrollToBottom(tabItem.key)
    // 结束打字
    streamTypewritingTool.endTypewriting()
  }

  // 监听滚动事件
  const scrollHandle = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target
    const isBottom = scrollTop + clientHeight >= scrollHeight
    if (scrollTop < e.target._prevScrollTop) {
      // console.log('向上滚动了')
      e.target._isScroll = false
    } else if (isBottom) {
      // console.log('到底了')
      e.target._isScroll = true
    }
    e.target._prevScrollTop = scrollTop
  }

  // 点击收藏
  const collectHandle = (tabItem: any) => {
    changeIsCollect(tabItem.key)
  }

  return (
    <div className={CSS.wrapper}>
      <Header
        isBack
        center={<Tbas list={tabsList} onChange={(v: any, i: number) => tabItemHandle(v, i)} />}
      />
      {tabsList.map((v, i) => {
        const currentCreationData = creationStore[v.key]
        return (
          <div
            ref={el => (scrollRefs[v.key] = el)}
            key={v.text}
            style={{ display: tabIndex === i ? 'block' : 'none' }}
            className={classNames([CSS.content_wrapper, 'scrollbar_style'])}
            onScroll={scrollHandle}
          >
            <div className={classNames([CSS.content])}>
              {/* 示例 */}
              <div className={CSS.example_box}>
                <div className={CSS.example_title}>参考示例</div>
                {currentCreationData.exampleList?.map((itez: any, indez: number) => (
                  <div
                    key={itez.id}
                    className={CSS.example_item}
                    onClick={() => exampleItemHandle(v, itez)}
                  >
                    <PenIcon />
                    <span>{itez.title}</span>
                  </div>
                ))}
              </div>
              {/* 输入框 */}
              <div className={CSS.input_box}>
                <textarea
                  placeholder="请输入你的问题"
                  value={currentCreationData.input}
                  onChange={e => inputHandle(e, v)}
                ></textarea>
              </div>
              {/* 按钮 */}
              <div className={CSS.btn_box}>
                <div>
                  <Button className="close_btn" onClick={() => changeInput(v.key, '')}>
                    清空
                  </Button>
                </div>
                <div>
                  {currentCreationData.isInput ? (
                    <Button
                      type="primary"
                      className="submit_btn"
                      icon={<TimeIonc1 />}
                      disabled
                      style={{ color: '#fff' }}
                    >
                      生成中
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      className="submit_btn"
                      onClick={() => submitPostStream(v)}
                      disabled={!currentCreationData.input}
                    >
                      提交
                    </Button>
                  )}
                </div>
              </div>
              {/* ai回复内容 */}
              {currentCreationData.assistant && (
                <div className={CSS.ai_content_box}>
                  {/* <MarkdownContent content="123 666" /> */}
                  <Markdown content={currentCreationData.assistant} />
                </div>
              )}

              {/* 按钮2 */}
              {currentCreationData.assistant && !currentCreationData.isInput && (
                <div className={CSS.btn2_box}>
                  <div>
                    <Button
                      icon={currentCreationData.isCollect ? <CollectYes /> : <CollectNo />}
                      onClick={() => collectHandle(v)}
                    >
                      {currentCreationData.isCollect ? '已收藏' : '收藏'}
                    </Button>
                  </div>
                  <div>
                    <Button
                      icon={<Reload1 />}
                      disabled={currentCreationData.isInput}
                      onClick={() => submitPostStream(v)}
                    >
                      再次生成
                    </Button>
                  </div>
                  <div>
                    <Button
                      type="primary"
                      icon={<Copy1 />}
                      onClick={() => copyHandle(currentCreationData.assistant)}
                    >
                      全部复制
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
})

export default Creation
