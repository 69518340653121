import React, { memo } from 'react'

const Close2 = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33325 2.96417H11.6666V6.29752H17.9166V9.63086H2.08325V6.29752H8.33325V2.96417Z"
        stroke="#4A5D82"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M3.33325 17.1667H16.6666V9.66669H3.33325V17.1667Z"
        stroke="#4A5D82"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M6.66675 17.124V14.6309"
        stroke="#4A5D82"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M10 17.124V14.624"
        stroke="#4A5D82"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 17.124V14.6309"
        stroke="#4A5D82"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M5 17.1667H15"
        stroke="#4A5D82"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
})

export default Close2
