import { create } from 'zustand'
import * as userAPI from '@/api/user'
import { message } from 'antd'
import useIndexStore from '.'
import cache from '@/utils/cache'

const store = (set: any) => ({
  userInfo: null,
  isShowPayGuidance: false,
  guidanceTitle: '',
  isShowHistory: false,
  changeIsShowHistory(isShowHistory: boolean) {
    set({
      isShowHistory,
    })
  },
  changeIsShowPayGuidance(isShowPayGuidance: boolean, guidanceTitle = '') {
    set(() => ({
      isShowPayGuidance,
      guidanceTitle,
    }))
  },
  async fetchUserInfo(data = {}, isLoad = true) {
    try {
      const token = useIndexStore.getState().token
      if (!token) {
        return
      }
      const res: any = await userAPI.getUserInfo(data, isLoad)
      if (res.code == 0) {
        message.error('登录过期，请重新登录')
        cache.clearCache()
        window.location.reload()
        return
      }
      if (res.code != 1) {
        message.error(res.msg)
        return
      }
      set({
        userInfo: res.data,
      })
    } catch (error) {
      console.log('error: ', error)
    }
  },
})

const useUserStore = create(store)

export default useUserStore
