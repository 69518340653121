import React from 'react'
import { Navigate } from 'react-router-dom'
import MainLayout from '@/main-layout'
import Chat from '@/pages/Chat'
import Assistant from '@/pages/Assistant'
import Creation from '@/pages/Creation'
import ChatTool from '@/pages/ChatTool'

// const Assistant = React.lazy(() => import('@/pages/Assistant'))
// const Creation = React.lazy(() => import('@/pages/Creation'))

export const defaultRouter = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/chat" />,
      },
      {
        path: '/chat',
        element: <Chat />,
      },
      {
        path: '/assistant',
        element: <Assistant />,
      },
      {
        path: '/creation',
        element: <Creation />,
      },
      {
        path: '/chat-tool',
        element: <ChatTool />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
    meta: {
      title: 'AIQ',
    },
  },
]
