import React, { memo, FC } from 'react'
import CSS from './index.module.scss'
import Left from './left'
import Right from './right'

const MaxLayout: FC = memo(() => {
  return (
    <div className={CSS.main}>
      <Left />
      <Right />
    </div>
  )
})

export default MaxLayout
