import useIndexStore from '@/store'
import { packBaseUrl } from '../request/config'
import axios from 'axios'
import { bjRequest } from '../'
import { getParameterByName } from '@/utils'

export function getSessionId(data = {}) {
  return bjRequest.post({
    url: '/api/chat/getSessionId',
    data,
  })
}

/**
 * 请求gpt流
 */
export function fetchPostStream(opstions: any) {
  const { data, cancelToken, onDownloadProgress, ...args } = opstions
  const token = useIndexStore.getState().token
  return axios({
    url: 'https://dev-chatapi.gzshibahao.com/api/chat/ai',
    method: 'POST',
    responseType: 'text',
    cancelToken,
    data,
    headers: {
      token,
      ctype: 'chatpc_1',
    },
    onDownloadProgress,
    ...args,
  })
}

/**
 * 请求gpt流2
 */
export async function fetchPostStream2(opstions: any) {
  const { data, onDownloadProgress, signal, ...args } = opstions
  const token = useIndexStore.getState().token

  const channel_no = getParameterByName('channel_no') ?? ''

  const resp = await fetch(`${packBaseUrl}/api/chat/ai`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-channel-no': channel_no,
      ctype: 'chatpc_1',
      token,
    },
    body: JSON.stringify(data),
    signal,
  })
  const reader = resp.body?.getReader()
  const textDecoder = new TextDecoder()
  if (reader) {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read()
      if (done) {
        break
      }
      const str = textDecoder.decode(value)
      // console.log('str: ', str)
      onDownloadProgress(str)
    }
  }
  return resp
}
