import React, { memo, useRef, useState } from 'react'
import CSS from './index.module.scss'
import { Button, Modal } from 'antd'

import { useRequest } from '@/hooks/useRequest'
import useIndexStore from '@/store'
import useUserStore from '@/store/user'
import Consent from './cpns/Consent'
import Login from './cpns/Login'
import Register from './cpns/Register'
import antdUI from '@/antd-ui'
import classNames from 'classnames'
import * as LoginAPI from '@/api/login'
import * as UserAPI from '@/api/user'
import RetrievePassword from './cpns/RetrievePassword'
import CacheComponent from '../CacheComponent'

const LoginPage = memo((props: any) => {
  const loginSwitch = useIndexStore(state => state.loginSwitch)
  const [type, setType] = useState(0)

  const { setToken, changeLoginSwitch } = useIndexStore()
  const { fetchUserInfo } = useUserStore()

  const loginReq = useRequest(LoginAPI.mobileLogin)
  const passwordLoginReq = useRequest(LoginAPI.login)
  const registerReq = useRequest(LoginAPI.register)
  const retrievePasswordReq = useRequest(UserAPI.forgetpwd)

  const loginRef = useRef<any>({})
  const registerRef = useRef<any>({})
  const retrievePasswordRef = useRef<any>({})
  const consentRef = useRef<any>({})

  const loginClick = async () => {
    const { verifyState, getTabIndex, loginFormData } = loginRef.current
    if (!verifyState()) return
    if (!consentRef.current.verifyState()) return
    const tabIndex = getTabIndex()
    let resArr: any
    if (tabIndex === 0) {
      resArr = await loginReq.run(loginFormData, {}, { code: 1 })
    } else if (tabIndex === 1) {
      resArr = await passwordLoginReq.run(loginFormData, {}, { code: 1 })
    }
    const [err, res] = resArr
    if (err) return
    const userInfo = res.data.userinfo
    setToken(userInfo.token)
    fetchUserInfo()
    changeLoginSwitch(false)
    antdUI.notificationApi.success({
      message: '登录成功~',
    })
  }
  const registerClick = async () => {
    const { verifyState, registerFormData } = registerRef.current
    if (!verifyState()) return
    if (!consentRef.current.verifyState()) return
    const [err, res] = await registerReq.run(registerFormData, {}, { code: 1 })
    if (err) return
    const userInfo = res.data.userinfo
    setToken(userInfo.token)
    fetchUserInfo()
    changeLoginSwitch(false)
    antdUI.notificationApi.success({
      message: '登录成功~',
    })
  }
  const retrievePasswordClick = async () => {
    const { verifyState, formData } = retrievePasswordRef.current
    if (!verifyState()) return
    const [err, res] = await retrievePasswordReq.run(
      { ...formData, newpassword: formData.password, confirm_password: formData.check_password },
      {},
      { code: 1 }
    )
    if (err) return
    antdUI.notificationApi.success({
      message: res.msg,
    })
    setTimeout(() => {
      setType(0)
    }, 500)
  }
  return (
    <Modal
      className={CSS.login_modal}
      width={'fit-content'}
      open={loginSwitch}
      footer={null}
      closeIcon={null}
    >
      <div className={CSS.modal_box}>
        <div className={CSS.modal_left}>
          <img src={require('@/assets/images/login.png')} alt="img" />
        </div>
        <div className={classNames([CSS.modal_right, 'flex', 'flex-col', 'justify-between'])}>
          <div>
            <div className={CSS.right_top_close}>
              <span className={CSS.right_top_title}>
                {type === 1 && '注册账号'}
                {type === 2 && '找回密码'}
              </span>
              <img
                onClick={() => changeLoginSwitch(false)}
                src={require('@/assets/images/icons/login_close.png')}
                alt="img"
              />
            </div>
            <CacheComponent show={type === 0}>
              <Login ref={loginRef} onSetType={setType} />
            </CacheComponent>
            <CacheComponent show={type === 1}>
              <Register ref={registerRef} />
            </CacheComponent>
            <CacheComponent show={type === 2}>
              <RetrievePassword ref={retrievePasswordRef} />
            </CacheComponent>
          </div>

          <div>
            <div className="mt-8 flex">
              <div className={CSS.login_btn1}>
                {type === 0 && <Button onClick={() => setType(1)}>去注册</Button>}
                {type === 1 && <Button onClick={() => setType(0)}>去登录</Button>}
                {type === 2 && <Button onClick={() => setType(0)}>返回</Button>}
              </div>
              <div className={CSS.login_btn2}>
                {type === 0 && (
                  <Button
                    type="primary"
                    onClick={loginClick}
                    loading={loginReq?.loading || passwordLoginReq.loading}
                  >
                    登录
                  </Button>
                )}
                {type === 1 && (
                  <Button type="primary" onClick={registerClick} loading={registerReq?.loading}>
                    注册并登录
                  </Button>
                )}
                {type === 2 && (
                  <Button
                    type="primary"
                    onClick={retrievePasswordClick}
                    loading={retrievePasswordReq?.loading}
                  >
                    重置密码
                  </Button>
                )}
              </div>
            </div>
            <div className="mt-4">
              <Consent ref={consentRef} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
})

export default LoginPage
