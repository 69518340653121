import React, { FC, memo, useState } from 'react'
import CSS from './index.module.scss'
import classNames from 'classnames'
import Praise1 from '@/assets/svg/Praise1'
import MoreIcon from '@/assets/svg/MoreIcon'
import { Popover } from 'antd'
import PraiseNoIcon from '@/assets/svg/PraiseNoIcon'
import CopyIcon2 from '@/assets/svg/CopyIcon2'
import DeleteIcon from '@/assets/svg/DeleteIcon'
import { Markdown } from '@/components/markdown'

import LoadingIcon from '@/assets/svg/LoadingIcon'
import useChatStore from '@/store/chat'
import RetryIcon from '@/assets/svg/RetryIcon'

import { copyTool } from '@/utils/tool'

interface IProps {
  item: {
    chat: {
      user: string
      assistant: string
    }
    isTool: boolean
    isLove: number
    created_at: string
    id: string | number
    isResetSession?: boolean
    done: boolean
  }
}

const MsgItem: FC<IProps> = memo((props: IProps) => {
  const [openPopover, setOpenPopover] = useState(false)
  const isInput = useChatStore(state => state.isInput)
  const deleteChatItem = useChatStore(state => state.deleteChatItem)
  const changeChatItemIsLove = useChatStore(state => state.changeChatItemIsLove)
  const changeRetrySessionChatItem = useChatStore(state => state.changeRetrySessionChatItem)
  const { item } = props
  const copyHandle = () => {
    copyTool(item.chat.assistant)
    setOpenPopover(false)
  }
  const deleteHandle = () => {
    deleteChatItem(item)
  }
  const thumbsUpHandle = (love?: any) => {
    if (love !== void 0) {
      changeChatItemIsLove(item, love)
      setOpenPopover(false)
      return
    }
    changeChatItemIsLove(item, item.isLove !== 0 ? 0 : 1)
  }

  const retrySessionHandle = () => {
    changeRetrySessionChatItem(item)
    setOpenPopover(false)
  }

  const aiMorePopoverElement = (
    <div className={CSS.prpover_content}>
      {item.isLove !== 2 && (
        <div className={CSS.prpover_con_item} onClick={() => thumbsUpHandle(2)}>
          <PraiseNoIcon />
          <span>不喜欢</span>
        </div>
      )}
      <div className={CSS.prpover_con_item} onClick={copyHandle}>
        <CopyIcon2 />
        <span>复制</span>
      </div>
      {!isInput && (
        <div className={CSS.prpover_con_item} onClick={retrySessionHandle}>
          <RetryIcon />
          <span>重试</span>
        </div>
      )}
      <div className={CSS.prpover_con_item} onClick={deleteHandle}>
        <DeleteIcon />
        <span className={CSS.delete_text}>删除</span>
      </div>
    </div>
  )
  return (
    <div className={CSS.msg_item}>
      {/* user */}
      {item.chat.user && (
        <div className={CSS.msg_item_user}>
          <div className={CSS.msg_user_content}>{item.chat.user}</div>
        </div>
      )}
      {/* ai */}
      <div className={CSS.msg_item_ai}>
        <div className={CSS.msg_item_wrapper}>
          <div className={CSS.msg_ai_content}>
            {isInput && !item.chat.assistant ? (
              <LoadingIcon />
            ) : (
              <Markdown content={item.chat.assistant} />
            )}

            {/* <Markdown content={item.chat.assistant} /> */}
            {item.isTool && item.done && (
              <div className={CSS.more_box}>
                <div
                  className={classNames(['filex_center', CSS.praise_box])}
                  onClick={() => thumbsUpHandle()}
                >
                  {item.isLove === 2 ? (
                    <PraiseNoIcon color="#FF8E25" />
                  ) : (
                    <Praise1 color={item.isLove === 1 ? '#0A78F9' : '#8E9DBA'} />
                  )}
                </div>
                <Popover
                  open={openPopover}
                  overlayClassName={CSS.more_popover1}
                  placement="bottomLeft"
                  content={aiMorePopoverElement}
                  trigger="click"
                  onOpenChange={e => setOpenPopover(e)}
                >
                  <div className={classNames([CSS.more_item, 'filex_center'])}>
                    <MoreIcon />
                  </div>
                </Popover>
              </div>
            )}
          </div>
          <div className={CSS.msg_ai_tool}>
            {item.created_at && <div className={CSS.msg_time}>{item.created_at}</div>}
          </div>
        </div>
      </div>
    </div>
  )
})

export default MsgItem
